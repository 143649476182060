import { useContext } from 'react';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { UserRight } from 'store/globalState/types';
import { AttachmentFileType } from 'store/sectionsState/interfaces';
import ImagePresenter from './ImagePresenter';
import SignableDocument from './SignableDocument';

const ModalEmbed = ({ document }: any) => {
  const { url, name, mimeType, type } = document;
  const { globalState } = useContext(GlobalStateContext);

  const FallbackEmbed = (
    <embed
      src={url}
      type={mimeType}
      title={name}
      data-testid="embed-default"
      style={{ height: '100%', width: '100%', objectFit: 'contain' }}
    />
  );

  switch (type) {
    case AttachmentFileType.IMAGE:
      return <ImagePresenter src={url} alt={name} />;
    case AttachmentFileType.VIDEO:
      return (
        <video controls data-testid="embed-video">
          <source src={url} type={mimeType} />
          {FallbackEmbed}
        </video>
      );
    case AttachmentFileType.SIGNABLE:
    case AttachmentFileType.SIGNED:
      if (
        type === AttachmentFileType.SIGNABLE &&
        !globalState.userRights?.check(UserRight.signDocument)
      )
        return FallbackEmbed;

      return <SignableDocument document={document} />;
    default:
      return FallbackEmbed;
  }
};

export default ModalEmbed;
