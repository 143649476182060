import { List, Typography } from '@material-ui/core';
import { ContactsSection } from 'store/sectionsState/interfaces';
import SectionCard from '../SectionCard/SectionCard';
import ContactComponent from './ContactComponent/ContactComponent';
import useGetContacts, { Contact } from '../../../hooks/useGetContacts';
import useT from 'hooks/useT';
import useStyles from './styles';

const MAX_COUNT = 3;

const Contacts: React.FC<ContactsSection> = (props) => {
  const contacts = useGetContacts(props.id);
  const text = useT();

  const classes = useStyles();
  const ContactList = contacts?.slice(0, MAX_COUNT).map((contact: Contact) => {
    return (
      <div className={classes.contact} key={`contact-${contact.id}`}>
        <ContactComponent {...contact} />
      </div>
    );
  });

  return (
    <SectionCard component={{ ...props, hideActionButton: contacts.length === 0 }}>
      {contacts?.length > 0 ? (
        <List disablePadding>{ContactList}</List>
      ) : (
        <Typography>{text('contacts.noContactsFound')}</Typography>
      )}
    </SectionCard>
  );
};

export default Contacts;
