import { fetchAttachments } from '../axiosInstances';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { useContext, useEffect, useState } from 'react';
import { ProjectsStateContext } from 'store/projectsState/ProjectsStateProvider';
import { ApartmentsStateContext } from 'store/apartmentsState/ApartmentsStateProvider';
import { AttachmentType } from 'store/sectionsState/interfaces';
import { AttachmentsResponse } from 'axiosInstances/instances/types';
import { AttachmentViewerStateContext } from 'store/attachmentViewerState/AttachmentViewerStateProvider';

export default function useGetAttachments(sectionId?: string, limit?: Number) {
  const { projectsState } = useContext(ProjectsStateContext);
  const { globalState } = useContext(GlobalStateContext);
  const { apartmentsState } = useContext(ApartmentsStateContext);
  const { attachmentViewerState } = useContext(AttachmentViewerStateContext);

  const { tenantId, organisationId } = globalState.startParameters;
  const gbToken = globalState.token;
  const projectId = projectsState.selectedProject?.id;
  const apartmentId = apartmentsState.selectedApartment?.id;

  const [error, setError] = useState<unknown>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<AttachmentType[]>([]);

  useEffect(() => {
    // TODO: we should not come this far without these
    if (!projectId || !apartmentId) return;

    setLoading(true);

    fetchAttachments({
      projectId: String(projectId),
      tenantId: String(tenantId),
      organisationId: String(organisationId),
      apartmentId: String(apartmentId),
      gbToken: String(gbToken),
      sectionId: sectionId ? String(sectionId) : '',
      limit
    })
      .then((response: AttachmentsResponse) => {
        setAttachments(response.data);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(setError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, apartmentId, attachmentViewerState.documentUpdated]);

  return { loading, attachments, error };
}
