import SectionCard from '../SectionCard/SectionCard';

import { Grid } from '@material-ui/core';
import Issue from './Issue/Issue';
import { IssueStatusListItem, IssueTrackerSection } from 'store/sectionsState/interfaces';
import useGetChecklistItems from './useGetChecklistItems';
import useT from 'hooks/useT';
import Progress from 'components/Progress/Progress';

export default function IssueTracker(props: IssueTrackerSection) {
  const t = useT();
  const { checklistItems, fetchingChecklistItems } = useGetChecklistItems();

  return (
    <SectionCard component={props} buttonText={t('view')} width={props.width}>
      {
        <Grid container direction="row">
          {checklistItems && checklistItems?.items?.length > 0 ? (
            <Grid item>
              {checklistItems.statuses.map((status: IssueStatusListItem, index: number) => {
                return (
                  <Issue
                    key={`status-list-item-${index}`}
                    status={status}
                    items={checklistItems?.items}
                  />
                );
              })}
            </Grid>
          ) : fetchingChecklistItems ? (
            <Grid item style={{ margin: 'auto' }}>
              <Progress />
            </Grid>
          ) : (
            <Grid item>
              <em>{t('checklist.noChecklistItems')}</em>
            </Grid>
          )}
        </Grid>
      }
    </SectionCard>
  );
}
