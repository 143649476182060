import React, { Dispatch, useMemo, useReducer } from 'react';
import projectsStateReducer from './projectsStateReducer';
import initialProjectsState from './initialProjectsState';
import { ProjectsStateReducerType, ProjectsState, ProjectsStateActionType } from './interfaces';

export const ProjectsStateContext = React.createContext<{
  projectsState: ProjectsState;
  dispatch: Dispatch<ProjectsStateActionType>;
}>({
  projectsState: initialProjectsState,
  dispatch: () => initialProjectsState
});

const { Provider } = ProjectsStateContext;

interface ProjectsStateProviderProps {
  children: React.ReactNode;
}

const ProjectsStateProvider: React.FC<ProjectsStateProviderProps> = ({ children }) => {
  const [projectsState, dispatch] = useReducer<ProjectsStateReducerType>(
    projectsStateReducer,
    initialProjectsState
  );

  const contextValue = useMemo(() => ({ projectsState, dispatch }), [projectsState, dispatch]);

  return <Provider value={contextValue}>{children}</Provider>;
};

export default ProjectsStateProvider;
