import { Grid, List, ListItem } from '@material-ui/core';
import { AnnouncementType, Sectionable } from 'store/sectionsState/interfaces';
import SectionCard from '../SectionCard/SectionCard';

import Announcement from './Announcement/Announcement';

import styles from './styles';

interface AnnouncementsType extends Sectionable {
  announcements: AnnouncementType[];
}

function Announcements(props: AnnouncementsType) {
  const classes = styles();
  const AnnouncementsList = props.announcements.map((announcement: AnnouncementType, index) => {
    return (
      <ListItem className={classes.listItem} key={`announcement-${index}`}>
        <Announcement {...announcement} />
      </ListItem>
    );
  });

  return (
    <SectionCard component={props}>
      <Grid container direction="column" className={classes.root}>
        <Grid item>
          <List disablePadding>{AnnouncementsList}</List>
        </Grid>
      </Grid>
    </SectionCard>
  );
}

export default Announcements;
