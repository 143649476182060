import { PhasesState } from './interfaces';

const initialPhasesState: PhasesState = {
  phases: [],
  fetchingPhases: false,
  phasesFetched: false,
  selectedPhase: null,
  selectingPhase: false
};

export default initialPhasesState;
