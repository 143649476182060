import { getProjectPhases } from 'axiosInstances';
import { useCallback, useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { PhasesStateAction } from 'store/phasesState/interfaces';
import { PhasesStateContext } from 'store/phasesState/PhasesStateProvider';
import { ProjectsStateContext } from 'store/projectsState/ProjectsStateProvider';

const usePhasesFetcher = () => {
  const { globalState } = useContext(GlobalStateContext);
  const { projectsState } = useContext(ProjectsStateContext);
  const { phasesState, dispatch } = useContext(PhasesStateContext);
  const params = useParams<{ phaseId?: string }>();

  const fetchPhases = useCallback(async () => {
    if (projectsState.selectedProject?.id && !phasesState.phasesFetched && globalState.token) {
      dispatch({ type: PhasesStateAction.SET_FETCHING_PHASES, data: true });

      const { data } = await getProjectPhases({
        projectId: String(projectsState.selectedProject.id),
        gbToken: globalState.token
      });

      dispatch({ type: PhasesStateAction.SET_PHASES, data: data.data });

      dispatch({
        type: PhasesStateAction.SET_SELECTED_PHASE,
        data: data.data.find((phase) => String(phase.id) === params.phaseId) || data.data[0] || null
      });
    }
  }, [
    projectsState.selectedProject,
    globalState.token,
    phasesState.phasesFetched,
    dispatch,
    params.phaseId
  ]);

  useEffect(() => {
    fetchPhases();
  }, [fetchPhases]);
};

export default usePhasesFetcher;
