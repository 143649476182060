// components
import Attachments from 'components/Sections/Attachments/Attachments';
import Announcements from 'components/Sections/Announcements/Announcements';
import Calendar from 'components/Sections/Calendar/Calendar';
import MaterialSelections from 'components/Sections/MaterialSelections/MaterialSelections';
import Folder from 'components/Sections/Folder/Folder';
import Surveys from 'components/Sections/Surveys/Surveys';
import IssueTracker from 'components/Sections/IssueTracker/IssueTracker';
import Contacts from 'components/Sections/Contacts/Contacts';
import { Section, SectionName } from 'store/sectionsState/interfaces';

export default function createSection(section?: Section, width?: number | string) {
  switch (section?.type) {
    case SectionName.ATTACHMENTS:
      return <Attachments key={`section-${section.id}`} {...section} width={width} />;
    case SectionName.ANNOUNCEMENTS:
      return <Announcements key={`section-${section.id}`} {...section} width={width} />;
    case SectionName.CALENDAR:
      return <Calendar key={`section-${section.id}`} {...section} width={width} />;
    case SectionName.MATERIAL_SELECTIONS:
      return <MaterialSelections key={`section-${section.id}`} {...section} width={width} />;
    case SectionName.FOLDER:
      return <Folder key={`section-${section.id}`} {...section} width={width} />;
    case SectionName.CONTACTS:
      return <Contacts key={`section-${section.id}`} {...section} width={width} />;
    case SectionName.SURVEYS:
      return <Surveys key={`section-${section.id}`} {...section} width={width} />;
    case SectionName.ISSUE_TRACKER:
      return <IssueTracker key={`section-${section.id}`} {...section} width={width} />;
    default:
      return undefined;
  }
}
