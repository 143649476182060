import { instance as axiosInstance } from 'axiosInstances';
import { createConfig } from 'axiosInstances/instances/utils';
import { useState, useEffect, useContext } from 'react';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { ProjectsStateContext } from 'store/projectsState/ProjectsStateProvider';

export interface ProjectSetting {
  key: string;
  value: string | number | boolean | null;
  origin?: string;
  group: string;
}

export interface ProjectSettingProperties {
  value: string | number | boolean | null;
  label?: string;
  description?: string;
  origin?: string;
  group: string;
}

interface ProjectSettings {
  loading: boolean;
  settings: ProjectSetting[];
}

/**
 * useProjectSettingsFetcher hook
 *
 * @returns - ProjectSettings
 */
export const useProjectSettingsFetcher = (): ProjectSettings => {
  const { globalState } = useContext(GlobalStateContext);
  const { projectsState } = useContext(ProjectsStateContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [settings, setSettings] = useState<ProjectSetting[]>([]);

  useEffect(() => {
    if (projectsState.selectedProject === null) return;

    setLoading(true);

    const { organisationId, tenantId } = globalState.startParameters;
    const projectId = projectsState.selectedProject?.id;

    const fetch = async () => {
      const response = await axiosInstance.get(
        `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/settings`,
        createConfig({ token: String(globalState.token) })
      );
      const { data } = response.data;
      const projectSettings: ProjectSetting[] = [];
      for await (const [key, value] of Object.entries(data.settings)) {
        const settingProperties = value as ProjectSettingProperties;
        projectSettings.push({ key, ...settingProperties } as ProjectSetting);
      }

      setSettings(projectSettings);
    };

    fetch()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsState.selectedProject?.id]);

  return { loading, settings };
};
