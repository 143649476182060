import SectionCard from '../SectionCard/SectionCard';
import defaultImage from './buildingMaterialsDefault.jpg';
import useStyles from './styles';
import { Grid, Typography } from '@material-ui/core';
import { useContext } from 'react';
import { MaterialSelectionsSection } from 'store/sectionsState/interfaces';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { ApartmentsStateContext } from 'store/apartmentsState/ApartmentsStateProvider';
import { ProjectsStateContext } from 'store/projectsState/ProjectsStateProvider';
import i18next from 'i18next';
import useT from 'hooks/useT';

export const useGetCJ2Url = () => {
  const { globalState } = useContext(GlobalStateContext);
  const { apartmentsState } = useContext(ApartmentsStateContext);
  const { projectsState } = useContext(ProjectsStateContext);

  return `${globalState.tenantSettings?.customerJourney2_url}?apartmentId=${apartmentsState.selectedApartment?.id}&tenantId=${globalState.startParameters.tenantId}&organisationId=${globalState.startParameters.organisationId}&projectId=${projectsState.selectedProject?.id}&gbToken=${globalState.token}&lang=${i18next.language}`;
};

export default function MaterialSelections(props: MaterialSelectionsSection) {
  const t = useT();
  const classes = useStyles();
  const { globalState } = useContext(GlobalStateContext);
  const cj2Image = globalState.tenantSettings?.cjMaterialSelectionImage;

  const CJ2Url = useGetCJ2Url();

  return (
    <SectionCard
      component={{ ...props, description: undefined }}
      action={() => window.open(CJ2Url, '_blank')?.focus()}
      buttonText={t('materialSelection.openLink')}
    >
      <Grid container direction="column" className={classes.root}>
        <Grid item>
          <img
            className={classes.image}
            alt={t('buildingMaterials')}
            src={cj2Image || defaultImage}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.description}>{props.description}</Typography>
        </Grid>
      </Grid>
    </SectionCard>
  );
}
