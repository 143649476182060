import React, { Dispatch, useMemo, useReducer } from 'react';
import chatStateReducer, { ChatStateReducerType } from './chatStateReducer';
import initialChatState from './initialChatState';
import { ChatStateType } from './types';
import { ChatStateActionType } from './actions';

export const ChatStateContext = React.createContext<{
  chatState: ChatStateType;
  dispatch: Dispatch<ChatStateActionType>;
}>({ chatState: initialChatState, dispatch: () => initialChatState });

interface ChatStateProviderProps {
  children: React.ReactNode;
}

const ChatStateProvider: React.FC<ChatStateProviderProps> = ({ children }) => {
  const { Provider } = ChatStateContext;
  const [chatState, dispatch] = useReducer<ChatStateReducerType>(
    chatStateReducer,
    initialChatState
  );
  const contextValue = useMemo(() => ({ chatState, dispatch }), [chatState, dispatch]);

  return <Provider value={contextValue}>{children}</Provider>;
};

export default ChatStateProvider;
