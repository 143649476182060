import Sections from 'routes/Home/Home';
import styles from './styles';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';

import SectionPage from 'routes/SectionView/Section';
import BackButton from 'components/BackButton/BackButton';
import useBasePath from 'hooks/useBasePath';

function ProjectPage() {
  const classes = styles();
  const basePath = useBasePath();
  const { path } = useRouteMatch();
  const appParams = useParams<{ sectionParentId: string }>();

  return (
    <>
      {appParams.sectionParentId !== undefined && <BackButton />}
      <div className={classes.sections}>
        <Switch>
          <Route path={`${basePath}/phase/:phaseId/section/:sectionId`} component={SectionPage} />
          <Route path={path} exact component={Sections} />
        </Switch>
      </div>
    </>
  );
}

export default ProjectPage;
