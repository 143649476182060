import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import useStyles from './styles';

export interface GBButtonProps {
  loading?: boolean;
  onClick: () => void;
}

export const GBButton: React.FC<GBButtonProps> = (props) => {
  const { loading, children, ...rest } = props;
  const classes = useStyles();

  return (
    <Button className={`${classes.button} ${loading ? 'loading' : ''}`} {...rest}>
      {loading && <CircularProgress className="progress" />}
      <div className="children">{children}</div>
    </Button>
  );
};
