import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      paddingLeft: '0.25rem',
      paddingRight: '0.25rem'
    },
    image: {
      width: '100%'
    },
    description: {
      color: theme.palette.common.black
    }
  });
});
