import React, { Dispatch, useMemo, useReducer } from 'react';
import apartmentsStateReducer from './apartmentsStateReducer';
import initialApartmentsState from './initialApartmentsState';
import {
  ApartmentsStateReducerType,
  ApartmentsState,
  ApartmentsStateActionType
} from './interfaces';

export const ApartmentsStateContext = React.createContext<{
  apartmentsState: ApartmentsState;
  dispatch: Dispatch<ApartmentsStateActionType>;
}>({
  apartmentsState: initialApartmentsState,
  dispatch: () => initialApartmentsState
});

const { Provider } = ApartmentsStateContext;

interface ApartmentsStateProviderProps {
  children: React.ReactNode;
}

const ApartmentsStateProvider: React.FC<ApartmentsStateProviderProps> = ({ children }) => {
  const [apartmentsState, dispatch] = useReducer<ApartmentsStateReducerType>(
    apartmentsStateReducer,
    initialApartmentsState
  );

  const contextValue = useMemo(() => ({ apartmentsState, dispatch }), [apartmentsState, dispatch]);

  return <Provider value={contextValue}>{children}</Provider>;
};

export default ApartmentsStateProvider;
