import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default makeStyles<Theme, { width?: number | string }>((theme: Theme) => {
  return createStyles({
    root: ({ width }) => ({
      minWidth: theme.typography.pxToRem(330),
      width: width || 'calc(33% - 1.25rem)',
      background: 'white',
      border: '1px solid #EAECF0',
      boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
      borderRadius: '8px',
      paddingTop: '1rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      marginRight: '0.75rem',
      marginLeft: '0.75rem',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.between('sm', 'lg')]: {
        minWidth: theme.typography.pxToRem(330),
        width: '1/2'
      },
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        marginBottom: '1rem'
      },
      '&:first-child:last-child': {
        width: '60%'
      },
      '&:first-child:nth-last-child(2)': {
        width: 'calc(50% - 1.5rem)'
      },
      '&:last-child:nth-child(2)': {
        width: 'calc(50% - 1.5rem)'
      }
    }),
    gridContainer: {
      flexGrow: 1
    },
    content: {
      flexGrow: 1,
      marginTop: '1rem'
    },
    title: {
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black,
      paddingBottom: 0,
      textTransform: 'uppercase'
    } as CSSProperties,
    subheader: {
      fontSize: theme.typography.body1.fontSize,
      color: theme.palette.common.black
    },
    actionArea: {
      display: 'flex',
      flexDirection: 'row-reverse',
      marginBottom: '1rem',
      marginTop: '.5rem'
    },
    pos: {
      marginBottom: 12
    },
    actionButtonText: {
      fontSize: theme.typography.subtitle1.fontSize
    },
    buttonLink: {
      textDecoration: 'none',
      color: theme.palette.primary.light
    },
    leftover: {
      background: theme.palette.common.black,
      paddingLeft: '0.5rem',
      marginLeft: '-0.25rem',
      marginRight: '-0.25rem',
      flexGrow: 1,
      backgroundColor: theme.palette.common.white
    },
    sectionHeader: {
      paddingBottom: '1rem !important',
      width: 'calc(100% - 8px)', // align border with header content
      margin: '0 auto', // align border with header content
      ...theme.custom?.sectionCard?.header,
      borderBottom: `0.15rem solid ${theme.palette.primary.main}`
    } as CSSProperties,
    icon: {
      display: theme.custom?.sectionCard?.buttonIcon?.display || 'none'
    }
  });
});
