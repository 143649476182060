import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: '0.8rem',
      paddingLeft: '0.4rem'
    },
    descriptionContainer: {
      paddingLeft: '0.5rem',
      paddingTop: '0.1rem'
    },
    descriptionTitle: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black,
      fontSize: theme.typography.body1.fontSize
    } as CSSProperties,
    descriptionDescription: {
      color: theme.palette.common.black,
      fontSize: theme.typography.subtitle2.fontSize,
      overflowWrap: 'break-word'
    }
  });
});
