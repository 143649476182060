import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => {
  return createStyles({
    listItem: {
      position: 'relative',
      padding: '8px 4px',

      '&:not(:first-child)': {
        paddingTop: '18px'
      },

      '& > div': {
        alignItems: 'flex-start'
      },

      '& .icon': {
        opacity: 0.5
      },

      '& .image': {
        borderRadius: '50%',
        overflow: 'hidden'
      }
    },
    name: {
      fontSize: theme.typography.subtitle1.fontSize
    },
    jobTitle: {
      color: theme.palette.common.black,
      opacity: 0.75,
      fontSize: theme.typography.subtitle2.fontSize
    },
    email: {
      color: theme.palette.common.black,
      opacity: 0.75,
      fontSize: theme.typography.subtitle2.fontSize
    },
    description: {
      color: theme.palette.common.black,
      opacity: 0.75,
      fontSize: theme.typography.subtitle2.fontSize,
      lineHeight: '1.1'
    }
  });
});
