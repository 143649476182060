import useStyles from './styles';
import { Grid, Typography } from '@material-ui/core';
import ContactListItem from './Contact/Contact';
import { ContactsSection } from 'store/sectionsState/interfaces';
import useGetContacts from '../../../hooks/useGetContacts';

export default function ContactsSectionPage(props: ContactsSection) {
  const classes = useStyles();
  const contacts = useGetContacts(props.id);

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography className={classes.pageHeader}>{props.name}</Typography>
      </Grid>
      <Grid item>
        <div className={classes.listContainer}>
          {contacts.map((contact, index) => (
            <ContactListItem key={`checklist-item-${index}`} {...contact} />
          ))}
        </div>
      </Grid>
    </Grid>
  );
}
