import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => {
  return createStyles({
    tag: {
      position: 'absolute',
      width: 'auto',
      height: '20px',
      padding: '0 .3rem',
      boxSizing: 'border-box',
      pointerEvents: 'none',
      backgroundColor: theme.palette.primary.light,
      textTransform: 'uppercase',
      right: '-23px',
      color: '#fff',
      top: '5px',
      zIndex: 1,
      backgroundImage: 'linear-gradient(90deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.1))',
      lineHeight: '19px',
      textAlign: 'center',

      '&::before': {
        content: '""',
        position: 'absolute',
        height: 0,
        width: 0,
        right: 0,
        borderRight: '7px solid transparent',
        borderTop: '5px solid ' + theme.palette.primary.main,
        bottom: ' -5px'
      }
    },
    text: {
      fontSize: '.75rem'
    }
  });
});
