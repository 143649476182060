const errorContainer = () => ({
  paddingTop: '7rem',
  margin: '0 auto'
});

const styles = () => ({
  errorContainer
});

export default styles;
