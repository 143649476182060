import ReactDOM from 'react-dom';
import './fonts/baseFonts.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router } from 'react-router-dom';
import { Switch, Route } from 'react-router';

import './i18n';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import GlobalStateProvider from 'store/globalState/GlobalStateProvider';
import ProjectsStateProvider from 'store/projectsState/ProjectsStateProvider';
import ApartmentsStateProvider from 'store/apartmentsState/ApartmentsStateProvider';
import PhasesStateProvider from 'store/phasesState/PhasesStateProvider';
import AttachmentViewerStateProvider from 'store/attachmentViewerState/AttachmentViewerStateProvider';
import SectionsStateProvider from 'store/sectionsState/SectionsStateProvider';
import ChatStateProvider from 'store/chatState/ChatStateProvider';
import { MessagingPaths } from '@groupbuilderoy/messaging-client';

ReactDOM.render(
  <Router>
    <GlobalStateProvider>
      <ProjectsStateProvider>
        <ApartmentsStateProvider>
          <ChatStateProvider>
            <PhasesStateProvider>
              <SectionsStateProvider>
                <AttachmentViewerStateProvider>
                  <ErrorBoundary>
                    <Switch>
                      <Route
                        path={['', MessagingPaths.ApartmentMessages, MessagingPaths.Bulletins]}
                        component={App}
                        exact
                      />
                      <Route
                        path="/project/:projectId/apartment/:apartmentId/phase/:phaseId/sectionParent/:sectionParentId"
                        component={App}
                      />
                      <Route
                        path="/project/:projectId/apartment/:apartmentId/phase/:phaseId"
                        component={App}
                      />
                      <Route path="/project/:projectId/apartment/:apartmentId" component={App} />
                      <Route path="/project/:projectId" component={App} />
                    </Switch>
                  </ErrorBoundary>
                </AttachmentViewerStateProvider>
              </SectionsStateProvider>
            </PhasesStateProvider>
          </ChatStateProvider>
        </ApartmentsStateProvider>
      </ProjectsStateProvider>
    </GlobalStateProvider>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
