import { fetchUserRights } from '../axiosInstances';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { useContext, useEffect } from 'react';
import { ProjectsStateContext } from 'store/projectsState/ProjectsStateProvider';
import { BaseResponse } from 'axiosInstances/instances/types';
import { GlobalStateAction } from 'store/globalState/interfaces';
import UserRights from 'store/globalState/UserRights';

export const useGetUserRights = (): void => {
  const { projectsState } = useContext(ProjectsStateContext);
  const { globalState, dispatch } = useContext(GlobalStateContext);

  const { tenantId, organisationId } = globalState.startParameters;
  const gbToken = globalState.token;
  const projectId = projectsState.selectedProject?.id;

  useEffect(() => {
    if (!projectId) return;

    fetchUserRights({
      projectId: String(projectId),
      tenantId: String(tenantId),
      organisationId: String(organisationId),
      token: String(gbToken)
    }).then((response: BaseResponse) => {
      dispatch({
        type: GlobalStateAction.SET_USER_RIGHTS,
        data: new UserRights(response.data.userRights)
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);
};
