import LanguageSelect from '../LanguageSelect';
import { Navigation } from '@groupbuilderoy/gb-components-library';
import { useContext, useEffect, useState } from 'react';
import { ChatStateAction } from 'store/chatState/actions';
import { ChatStateContext } from 'store/chatState/ChatStateProvider';
import ApartmentSelect from './ToolBar/ApartmentSelect/ApartmentSelect';
import ProjectSelect from './ToolBar/ProjectSelect/ProjectSelect';
import useStyles from './Header.styles';
import useT from 'hooks/useT';
import { ProjectsStateContext } from 'store/projectsState/ProjectsStateProvider';
import { useShowMessaging } from 'hooks/useShowMessaging';
import { MessagingPaths } from '@groupbuilderoy/messaging-client';
import { useHistory } from 'react-router-dom';
import useGetCustomizationSettings from 'hooks/useGetCustomizationSettings';
import User from './ToolBar/User/User';

function Header() {
  const history = useHistory();
  const classes = useStyles();
  const { dispatch } = useContext(ChatStateContext);
  const [selectedTab, setSelectedTab] = useState<number>(
    Object.values(MessagingPaths).some((path) => path === history.location.pathname) ? 1 : 0
  );
  const t = useT();
  const { projectsState } = useContext(ProjectsStateContext);
  const { messagingEnabled, newMessagingEnabled } = useShowMessaging();
  const customizationSettings = useGetCustomizationSettings();

  useEffect(() => {
    setSelectedTab(
      Object.values(MessagingPaths).some((path) => path === history.location.pathname) ? 1 : 0
    );
  }, [projectsState.selectedProject?.id, history.location.pathname]);

  let tabs = [
    {
      caption: t('home'),
      onClickTab: () => {
        history.push('/');
        dispatch({
          type: ChatStateAction.SET_CHAT_VISIBILITY,
          data: false
        });
      }
    }
  ];
  if (projectsState.selectedProject?.canUseMessaging && messagingEnabled) {
    tabs.push({
      caption: t('messages'),
      onClickTab: () => {
        if (newMessagingEnabled) history.push(MessagingPaths.ApartmentMessages);
        else dispatch({ type: ChatStateAction.SET_CHAT_VISIBILITY, data: true });
      }
    });
  }
  return (
    <div className={classes.root}>
      <Navigation
        logoUrl={customizationSettings.assets?.logo}
        headerImgUrl={customizationSettings.assets?.headerImg}
        selectedTab={selectedTab}
        tabs={tabs.map((tab, index) => {
          return {
            id: index,
            ...tab
          };
        })}
        rightItems={
          <>
            <ProjectSelect />
            <ApartmentSelect />
            <LanguageSelect />
            <div className={classes.userActions}>
              <User />
            </div>
          </>
        }
      />
    </div>
  );
}

export default Header;
