import { SectionsState } from './interfaces';

const initialSectionsState: SectionsState = {
  sections: [],
  sectionsFetched: false,
  fetchingSections: false,
  selectedSection: null,
  selectingSection: false
};

export default initialSectionsState;
