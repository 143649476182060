import { Typography, useTheme } from '@material-ui/core';
import DateSquare from 'components/DateSquare/DateSquare';
import { EventType } from 'store/sectionsState/interfaces';
import useStyles from './styles';

interface ListEventPropsType {
  event: EventType;
}

export function ListEvent({ event }: ListEventPropsType) {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <div className={styles.root}>
      <DateSquare
        showTime
        startDateTimeString={event.startDateTime}
        endDateTimeString={event.endDateTime}
        color={!!event.endDateTime ? theme.palette.error.main : theme.palette.primary.main}
      />
      <div className={styles.descriptionContainer}>
        <Typography className={styles.descriptionTitle}>{event.name}</Typography>
        <Typography className={styles.descriptionDescription}>{event.description}</Typography>
      </div>
    </div>
  );
}

export interface CalendarPropsType {
  events: EventType[];
}
export default function CalendarList(props: CalendarPropsType) {
  return (
    <>
      {/* {events.map((event, index) => (
        <ListEvent key={`list-event-${index}`} event={event} />
      ))} */}
    </>
  );
}
