import { GlobalState, GlobalStateAction, GlobalStateActionType } from './interfaces';

const globalStateReducer = (
  globalState: GlobalState,
  action: GlobalStateActionType
): GlobalState => {
  switch (action.type) {
    case GlobalStateAction.SETUP:
      return {
        ...globalState,
        startParameters: action.data
      };
    case GlobalStateAction.SET_APPLICATION_ERROR:
      return {
        ...globalState,
        applicationError: action.data
      };
    case GlobalStateAction.SET_TENANT_SETTINGS:
      return {
        ...globalState,
        tenantSettings: action.data,
        fetchingTenantSettings: false,
        tenantSettingsFetched: true
      };
    case GlobalStateAction.SET_TOKEN:
      return {
        ...globalState,
        token: action.data
      };
    case GlobalStateAction.SET_USER:
      return {
        ...globalState,
        user: action.data
      };
    case GlobalStateAction.SET_FETCHING_TENANT_SETTINGS:
      return {
        ...globalState,
        fetchingTenantSettings: action.data
      };
    case GlobalStateAction.SET_TENANT_SETTINGS_FETCHED:
      return {
        ...globalState,
        tenantSettingsFetched: action.data
      };
    case GlobalStateAction.SET_USER_RIGHTS:
      return {
        ...globalState,
        userRights: action.data
      };
    default:
      throw new Error(`Bad reducer action.`);
  }
};

export default globalStateReducer;
