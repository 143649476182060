import { AxiosResponse } from 'axios';
import { instance } from 'axiosInstances';
import { BaseResponse } from './types';
import { createConfig, createPrivatePath } from './utils';

export interface LoginResponse {
  data: {
    userID: number;
    name: string;
    userLevel: number;
    canUpload: boolean;
    email: string;
  };
  error: null | string;
  token: string;
}

export const login = (
  username: string,
  password: string,
  tenantId: string
): Promise<AxiosResponse<LoginResponse>> =>
  instance.post(`/v1/public/accounts/login`, {
    username,
    password,
    schema: tenantId
  });

export interface TokenExchangeResponse {
  data: {
    token: string;
    user: {
      auth0Id: string;
      email: string;
      name: string;
      userId: number;
      userLevel: number;
    };
  };
}

export const tokenExchange = (
  company: string,
  tenantId: string,
  accessToken: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<TokenExchangeResponse>> =>
  instance.get(
    `/v6/public/organisations/${company}/tenants/${tenantId}/token-exchange`,
    createConfig({ token: accessToken })
  );

export const fetchUserRights = async ({
  token,
  organisationId,
  tenantId,
  projectId
}: any): Promise<any> => {
  const response = (await instance.get(
    createPrivatePath({ organisationId, tenantId, projectId }) + '/user-rights',
    createConfig({ token })
  )) as BaseResponse;

  if (response.error) throw new Error('Unable to fetch user rights!');

  return response.data;
};

export const getProjectsWithApartments = async (token: string) => {
  try {
    const response = (await instance.get(
      createPrivatePath({}, 'v2') + '/projects/with-apartments',
      createConfig({ token })
    )) as BaseResponse;

    if (response.error || !response.data)
      throw new Error('Invalid response for projects and apartments');

    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
};
