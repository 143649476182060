import useStyles from './styles';
import { Typography } from '@material-ui/core';
import { Section } from 'store/sectionsState/interfaces';
import useGetAttachments from 'hooks/useGetAttachments';
import useT from 'hooks/useT';
import AttachmentListItem from 'components/Attachments/AttachmentList/AttachmentListItem';
import Progress from 'components/Progress/Progress';
import { Alert } from '@material-ui/lab';

export default function AttachmentSectionPage(props: Section) {
  const classes = useStyles();
  const { loading, attachments, error } = useGetAttachments(String(props.id));
  const t = useT();

  const content = () => {
    if (loading) {
      return <Progress />;
    } else if (error) {
      return (
        <Alert variant="outlined" severity="error">
          {t('attachments.fetchFailed')}
        </Alert>
      );
    } else if (!attachments?.length) {
      return <Alert severity="info">{t('attachments.noAttachments')}</Alert>;
    }

    return attachments.map((attachment, index) => {
      return (
        <AttachmentListItem
          key={`attachment-${index}`}
          attachment={attachment}
          className={classes.listItem}
        />
      );
    });
  };

  return (
    <>
      <Typography className={classes.heading}>{props.name}</Typography>
      <div className={classes.listContainer}>{content()}</div>
    </>
  );
}
