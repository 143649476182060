import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    listContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      '&&>li': {
        borderBottom: '1px solid lightgray',
        maxWidth: 'calc(100%/3 - 2rem)',

        padding: '8px 4px',
        margin: '2rem 1rem',
        [theme.breakpoints.down('md')]: {
          maxWidth: '100%',
          margin: '1rem 0'
        }
      }
    },
    listItem: {
      fontSize: '2rem'
    },
    heading: {
      fontSize: theme.typography.h5.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      margin: '1rem 0 1.5rem'
    } as CSSProperties
  })
);
