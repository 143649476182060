interface VisibilityLevel {
  name: string;
  id: number;
}

interface OrganisationTracking {
  isDefault: boolean;
  organisationTextId: string;
  trackingId: string;
  type: string;
}

interface QuantityTypeUnit {
  abbreviation: string;
  id: string;
}

interface QuantityType {
  qtyTypeId: string;
  name: string;
  displayUnitId: string;
  units: QuantityTypeUnit[];
  dbUnitId: string;
}

interface UnitQuantityType {
  dbUnitId: string;
  id: string;
  roundTo: number;
  units: QuantityTypeUnit[];
}

interface Unit {
  dbUnitId: string;
  displayUnitId: string;
  pricePrecision: number;
  qtyType: UnitQuantityType;
  qtyTypeId: string;
}

interface GB4DUxMode {
  cameraMode: number;
  uiMode: number;
  showUi: boolean;
  showThemes: boolean;
  showMaterials: boolean;
  showDecos: boolean;
  showFirstPerson: boolean;
  showDive: boolean;
  enableTutorial: boolean;
  enableOnClickBimSelection: boolean;
  enableObjectHighlight: boolean;
  showPrimaryPanel: boolean;
  showInputPanel: boolean;
  showZoomButtons: boolean;
  showStoreySelection: boolean;
  nextUiMode: number;
}

interface APIEnum {
  id: string;
  name: string;
}

interface ThemeCustomizationSettings {
  palette: {
    main: string;
    secondary: string;
    navigationBar: string;
    navigationBarText: string;
  };
  assets: {
    logo: string;
    headerImg: string;
  };
}

export interface TenantSettings {
  GB4dConfigName: string;
  bimVaultApiUrl: string;
  defaultColumnWidth: number | string;
  ccUseNotes: boolean;
  cjCompanyResourcesFolder: string;
  cjMaterialSelectionImage: string;
  themeCustomizationSettings: ThemeCustomizationSettings;
  coreApiUrl: string;
  coreClientUrl: string;
  currencyCode: string;
  currencySymbol: string;
  customerJourney2_alwaysShowConfirmAllSelections: boolean;
  customerJourney2_isActive: boolean;
  customerJourney2_isLockingByBundleEnabled: boolean;
  customerJourney2_isLockingByDeadlineEnabled: boolean;
  customerJourney2_mainImage: string;
  customerJourney2_showEventsBox: boolean;
  customerJourney2_showFooter: boolean;
  customerJourney2_showHeader: boolean;
  customerJourney2_showOnlyPackageBundles: boolean;
  customerJourney2_showPackageBasedSummary: boolean;
  customerJourney2_showRoomBasedSummary: boolean;
  customerJourney2_showRoomPrices: boolean;
  customerJourney2_showRooms: boolean;
  customerJourney2_url: string;
  customerJourney2_useGiosg: boolean;
  customerJourney2_usePackageBundlesConfirm: boolean;
  customerJourney2_usePackageSets: boolean;
  displayUnitArea: string;
  displayUnitLength: string;
  displayUnitMass: string;
  displayUnitTime: string;
  displayUnitVolume: string;
  enums: {
    bundle: {
      status: APIEnum[];
      visibleIn: APIEnum[];
    };
    esign: {
      status: APIEnum[];
      type: APIEnum[];
    };
    materialCategory: {
      selection: APIEnum[];
    };
    publishState: APIEnum[];
  };
  environmentLocale: string;
  environmentUrl: string;
  esign_requireForMaterialLocking: boolean;
  gb4dHdUrl: string;
  gb4dIsActive: boolean;
  gb4dPublicCanSeeMaterialDetails: boolean;
  gb4dUrl: string;
  gb4dUsePublicLinks: boolean;
  gb4dUxModes: null | GB4DUxMode[];
  loginImageUrl: string;
  login_useDirectLogin: boolean;
  login_useSso: boolean;
  logoUrls: {
    organisationLogoDarkUrl: string;
    organisationLogoLightUrl: string;
  };
  materialPriceCommission: string;
  materialPriceVat: string;
  mobileHasCustomApp: boolean;
  organisationId: string;
  organisationName: string;
  organisationTracking: OrganisationTracking[];
  portal_RequireLogin: boolean;
  pricePrecision: number;
  publicCoreApiUrl: string;
  quantityTypes: QuantityType[];
  regionName: string;
  sendgridPasswordChangeTemplateId: string;
  storageAccount: string;
  storageContainer: string;
  supportEmail: string;
  timeZone: string;
  units: {
    AREA: Unit;
    LENGTH: Unit;
    MASS: Unit;
    PCS: Unit;
    TIME: Unit;
    VOLUME: Unit;
  };
  useAuth0: boolean;
  useBundlePrices: boolean;
  useBundleSystem2: boolean;
  useDigitalSignatures: boolean;
  useMessaging: boolean;
  useTheme: boolean;
  userlaneCc: boolean;
  userlaneCj2: boolean;
  userlaneCore: boolean;
  userlaneGb4d: boolean;
  userlaneKey: null | string;
  visibilityLevels: VisibilityLevel[];
  useNewMessaging: boolean;
}

export enum UserRight {
  signDocument = 'signable_attachments:sign'
}
