import { enUS, fi, nl, fr, sv, de, ru, enGB, nb } from 'date-fns/locale';
import i18n from 'i18next';

export interface LanguageType {
  code: string;
  title: string;
  dateFnsLocaleKey: string;
  dateFnsLocaleValue: Locale;
}

export const languages: LanguageType[] = [
  {
    code: 'en-US',
    title: 'English (US)',
    dateFnsLocaleKey: 'en',
    dateFnsLocaleValue: enUS
  },
  {
    code: 'en-GB',
    title: 'English (GB)',
    dateFnsLocaleKey: 'enGB',
    dateFnsLocaleValue: enGB
  },
  {
    code: 'fi-FI',
    title: 'Suomi',
    dateFnsLocaleKey: 'fi',
    dateFnsLocaleValue: fi
  },
  {
    code: 'nb-NO',
    title: 'Norsk',
    dateFnsLocaleKey: 'nb',
    dateFnsLocaleValue: nb
  },
  {
    code: 'nl-NL',
    title: 'Nederlands',
    dateFnsLocaleKey: 'nl',
    dateFnsLocaleValue: nl
  },
  {
    code: 'fr-FR',
    title: 'Français',
    dateFnsLocaleKey: 'fr',
    dateFnsLocaleValue: fr
  },
  {
    code: 'sv-SE',
    title: 'Swedish',
    dateFnsLocaleKey: 'sv',
    dateFnsLocaleValue: sv
  },
  {
    code: 'de-DE',
    title: 'German',
    dateFnsLocaleKey: 'de',
    dateFnsLocaleValue: de
  },
  {
    code: 'ru-RU',
    title: 'Russian',
    dateFnsLocaleKey: 'ru',
    dateFnsLocaleValue: ru
  }
];

export const getLocales = (): { [index: string]: Locale } => {
  const locales: { [index: string]: Locale } = {};
  languages.forEach((language) => {
    locales[language.code] = language.dateFnsLocaleValue;
  });
  return locales;
};

export const getDateFnsLocale = (): Locale => {
  return getLocales()[i18n.language || 'en'];
};
