import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    sections: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      position: 'relative',
      zIndex: 1,
      rowGap: '3rem'
    }
  })
);
