import React, { Dispatch, useMemo, useReducer } from 'react';
import phasesStateReducer from './phasesStateReducer';
import initialPhasesState from './initialPhasesState';
import { PhasesStateReducerType, PhasesState, PhasesStateActionType } from './interfaces';

export const PhasesStateContext = React.createContext<{
  phasesState: PhasesState;
  dispatch: Dispatch<PhasesStateActionType>;
}>({ phasesState: initialPhasesState, dispatch: () => initialPhasesState });

const { Provider } = PhasesStateContext;

interface PhasesStateProviderProps {
  children: React.ReactNode;
}

const PhasesStateProvider: React.FC<PhasesStateProviderProps> = ({ children }) => {
  const [phasesState, dispatch] = useReducer<PhasesStateReducerType>(
    phasesStateReducer,
    initialPhasesState
  );

  const contextValue = useMemo(() => ({ phasesState, dispatch }), [phasesState, dispatch]);

  return <Provider value={contextValue}>{children}</Provider>;
};

export default PhasesStateProvider;
