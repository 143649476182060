import StartUrlParameters from '../../utils/StartUrlParameters';
import { TenantSettings } from './types';
import UserRights from './UserRights';

export enum GlobalStateAction {
  SETUP = 'SETUP',
  SET_TENANT_SETTINGS = 'SET_TENANT_SETTINGS',
  SET_APPLICATION_ERROR = 'SET_APPLICATION_ERROR',
  SET_TOKEN = 'SET_TOKEN',
  SET_USER = 'SET_USER',
  SET_FETCHING_TENANT_SETTINGS = 'SET_FETCHING_TENANT_SETTINGS',
  SET_TENANT_SETTINGS_FETCHED = 'SET_TENANT_SETTINGS_FETCHED',
  SET_USER_RIGHTS = 'SET_USER_RIGHTS'
}

export interface User {
  userID?: number;
  name?: string;
  canUpload?: boolean;
  email?: string;
  auth0Id?: string;
  userId?: number;
  fetchingUserLevel?: boolean;
  userLevel?: number;
}

export interface StartUrlParametersInterface {
  [StartUrlParameters.organisationId]?: string;
  [StartUrlParameters.lan]?: string;
  [StartUrlParameters.tenantId]?: string;
  [StartUrlParameters.projectId]?: string;
  [StartUrlParameters.apartmentId]?: string;
  [StartUrlParameters.accessToken]?: string;
  [StartUrlParameters.authToken]?: string;
  [StartUrlParameters.username]?: string;
  [StartUrlParameters.password]?: string;
}

export interface GlobalState {
  startParameters: StartUrlParametersInterface;
  tenantSettings: Partial<TenantSettings>;
  applicationError: Error | null;
  token: null | string;
  user?: User;
  fetchingTenantSettings: boolean;
  tenantSettingsFetched: boolean;
  userRights: UserRights | null;
}

export interface SetupAction {
  type: GlobalStateAction.SETUP;
  data: StartUrlParametersInterface;
}
export interface SetApplicationErrorAction {
  type: GlobalStateAction.SET_APPLICATION_ERROR;
  data: Error | null;
}

export interface SetTokenAction {
  type: GlobalStateAction.SET_TOKEN;
  data: null | string;
}

export interface SetTenantSettingsAction {
  type: GlobalStateAction.SET_TENANT_SETTINGS;
  data: TenantSettings;
}

export interface SetApplicationErrorAction {
  type: GlobalStateAction.SET_APPLICATION_ERROR;
  data: Error | null;
}

export interface SetUserAction {
  type: GlobalStateAction.SET_USER;
  data: User;
}

export interface SetTokenAction {
  type: GlobalStateAction.SET_TOKEN;
  data: null | string;
}

export interface SetFetchingTenantSettingsAction {
  type: GlobalStateAction.SET_FETCHING_TENANT_SETTINGS;
  data: boolean;
}

export interface SetTenantSettingsFetchedAction {
  type: GlobalStateAction.SET_TENANT_SETTINGS_FETCHED;
  data: boolean;
}

export interface SetUserRightsAction {
  type: GlobalStateAction.SET_USER_RIGHTS;
  data: UserRights;
}

export type GlobalStateReducerType = (
  state: GlobalState,
  action: GlobalStateActionType
) => GlobalState;

export type GlobalStateActionType =
  | SetupAction
  | SetApplicationErrorAction
  | SetTokenAction
  | SetTenantSettingsAction
  | SetUserAction
  | SetFetchingTenantSettingsAction
  | SetTenantSettingsFetchedAction
  | SetUserRightsAction;
