export interface Sectionable {
  description?: string;
  parentSectionId: string | null;
  buttonText?: string;
  children?: any;
  action?: Function;
  width?: number | string;
  ordernumber: number;
  type: number;
  imagefilename: string | null;
  phaseId: number;
  id: number;
  name: string;
  hideActionButton: boolean;
}

export interface DeadlineType {
  description: null | string;
  endTime: string;
  startTime: string;
  registrationDeadline: null | string;
  selectedApartmentsOnly: boolean;
  authorAuthId: string;
  question1: null | string;
  question2: null | string;
  timeCreated: string;
  type: DeadlineTypeType;
  id: number;
  name: string;
}

export enum DeadlineTypeType {
  TYPE_BASIC = 'TYPE_BASIC'
}

export interface DeadlinesSection extends Sectionable {
  type: SectionName.DEADLINES;
  deadlines: DeadlineType[];
}

export interface IssueTrackerSection extends Sectionable {
  type: SectionName.ISSUE_TRACKER;
  statuses: any;
  items: IssueTrackerItemType[];
}

export interface IssueStatusListItem {
  id: IssueState;
  name: string;
  shortName: string;
  isDefault: boolean;
  color: string;
  order: number;
}

export interface IssueTrackerType extends Sectionable {
  statuses: any;
  items: IssueTrackerItemType[];
}

export interface IssueTrackerItemType {
  id: number;
  name: string;
  issueType: number;
  imageUrl: string | null;
  imageUrlThumbnail: string | null;
  created: number;
  deadline: null | number;
  roomId: number;
  roomName: string;
  status: IssueState;
  description: string;
  commentToCustomer: string | null;
  assignedUserName: string | null;
  assignedUserId: null | number;
  modified: null | number;
  pureImageUrl: string | null;
}

export enum IssueState {
  NO_ACTION_NEEDED = 'STATE_NO_ACTION_NEEDED',
  APPROVED = 'STATE_APPROVED',
  FIXED = 'STATE_FIXED',
  ACCEPTED = 'STATE_ACCEPTED',
  NEW = 'STATE_NEW'
}

export interface ContactsSection extends Sectionable {
  type: SectionName.CONTACTS;
}

export interface SurveysSection extends Sectionable {
  type: SectionName.SURVEYS;
  surveys: SurveyType[];
}

export interface SurveyType {
  name: string;
  id: number;
  description: string;
}

export interface FolderSection extends Sectionable {
  type: SectionName.FOLDER;
}

export interface FolderType extends Sectionable {}

export interface MaterialSelectionsSection extends Sectionable {
  type: SectionName.MATERIAL_SELECTIONS;
  url: string;
}

export interface EventType {
  startDateTime: string;
  endDateTime?: string;
  description?: string;
  id: number;
  name: string;
}

export interface CalendarSection extends Sectionable {
  type: SectionName.CALENDAR;

  events: EventType[];
}

export interface AnnouncementsSection extends Sectionable {
  type: SectionName.ANNOUNCEMENTS;
  announcements: AnnouncementType[];
}

export interface AnnouncementType {
  url: string;
  description: string;
  id: number;
  name: string;
}

export enum SectionName {
  ATTACHMENTS = 4,
  ANNOUNCEMENTS = 10,
  CALENDAR = 5,
  MATERIAL_SELECTIONS = 2,
  EVENTS = 11,
  FOLDER = 0,
  ISSUE_TRACKER = 6,
  SURVEYS = 7,
  CONTACTS = 1,
  DEADLINES = 12
}

export interface AttachmentsSection extends Sectionable {
  type: SectionName.ATTACHMENTS;
  attachments: AttachmentType[];
}

export interface SectionType {
  sectionId: number;
  phaseId: number;
}

export interface AttachmentType {
  description?: string;
  createdOn?: string;
  url: string;
  projectId?: number;
  context?: ContextType;
  apartmentId?: null | number;
  id: number;
  name: string;
  type?: AttachmentFileType;
  tag?: string;
  xfdf?: string;
  mimeType: string;
  sectionId?: string;
  phaseId?: string;
  signed?: boolean;
  signable?: boolean;
  sections?: SectionType[];
}

export enum AttachmentFileType {
  PDF = 'pdf',
  IMAGE = 'image',
  VIDEO = 'video',
  SIGNABLE = 'signable',
  SIGNED = 'signed',
  OTHER = 'other'
}

export interface ContextType {
  tenantId: string;
  domain: string;
  id: number;
  name: string;
  itemType: string;
}

export type Section =
  | AttachmentsSection
  | AnnouncementsSection
  | CalendarSection
  | MaterialSelectionsSection
  | FolderSection
  | SurveysSection
  | ContactsSection
  | IssueTrackerSection
  | DeadlinesSection;

export enum SectionsStateAction {
  SET_SECTIONS = 'SET_SECTIONS',
  SET_FETCHING_SECTIONS = 'SET_FETCHING_SECTIONS',
  SET_SECTIONS_FETCHED = 'SET_SECTIONS_FETCHED',
  SET_SELECTED_SECTION = 'SET_SELECTED_SECTION',
  SET_SELECTING_SECTION = 'SET_SELECTING_SECTION'
}

export interface SectionsState {
  sections: Section[];
  fetchingSections: boolean;
  sectionsFetched: Boolean;
  selectedSection: null | Section;
  selectingSection: boolean;
}

export interface SetSectionsAction {
  type: SectionsStateAction.SET_SECTIONS;
  data: Section[];
}

export interface SetFetchingSectionsAction {
  type: SectionsStateAction.SET_FETCHING_SECTIONS;
  data: boolean;
}

export interface SetSectionsFetchedAction {
  type: SectionsStateAction.SET_SECTIONS_FETCHED;
  data: boolean;
}

export interface SetSelectedSectionAction {
  type: SectionsStateAction.SET_SELECTED_SECTION;
  data: Section | null;
}

export interface SetSelectingSectionAction {
  type: SectionsStateAction.SET_SELECTING_SECTION;
  data: boolean;
}

export type SectionsStateActionType =
  | SetSectionsAction
  | SetFetchingSectionsAction
  | SetSectionsFetchedAction
  | SetSelectedSectionAction
  | SetSelectingSectionAction;

export type SectionsStateReducerType = (
  state: SectionsState,
  action: SectionsStateActionType
) => SectionsState;
