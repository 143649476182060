import { Grid, TextField } from '@material-ui/core';
import { SendOutlined } from '@material-ui/icons';
import { postRoomMessages } from 'axiosInstances';
import { PostMessageResponseType } from 'axiosInstances/instances/types';
import useT from 'hooks/useT';
import i18n from 'i18next';
import { useContext, useState } from 'react';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import styles from './styles';

interface NewMessageProps {
  onSendHandler: (response: PostMessageResponseType, message: string) => void;
  disabled?: boolean;
  roomId?: number;
}

export default function NewChatMessageForm({ onSendHandler, disabled, roomId }: NewMessageProps) {
  const classes = styles();
  const t = useT();
  const [message, setMessage] = useState('');

  const { globalState } = useContext(GlobalStateContext);
  const gbToken = globalState.token;

  const onClickSend = async () => {
    if (roomId && !disabled && gbToken && roomId) {
      const response = await postRoomMessages({
        gbToken,
        roomId: String(roomId),
        messageData: {
          messageContent: message,
          messageType: 'customer',
          locale: i18n.language
        }
      });
      onSendHandler(response, message);
      setMessage('');
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  return (
    <Grid item className={classes.root}>
      <TextField
        disabled={disabled}
        className={classes.input}
        value={message}
        multiline
        onChange={handleChange}
        onKeyPress={(e) => (e.key === 'Enter' ? onClickSend() : null)}
        variant="outlined"
        placeholder={t('chat.sendMessage')}
      ></TextField>
      <SendOutlined fontSize="large" className={classes.sendIcon} onClick={onClickSend} />
    </Grid>
  );
}
