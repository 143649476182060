import axios, { AxiosResponse } from 'axios';
import { Apartment } from 'store/apartmentsState/interfaces';
import { TenantSettings } from 'store/globalState/types';
import { Phase } from 'store/phasesState/interfaces';
import { Project } from 'store/projectsState/interfaces';

import { createConfig } from './utils';

export const instance = axios.create(createConfig({}));

export interface GetTenantSettingsResponse {
  data: TenantSettings;
}

export const getTenantSettings = (
  organisationId: string,
  tenantId: string,
  token?: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<GetTenantSettingsResponse>> =>
  instance.get(
    `v6/${
      token ? 'private' : 'public'
    }/organisations/${organisationId}/tenants/${tenantId}/settings`,
    createConfig({ token })
  );

interface GetProjectsParams {
  gbToken: string;
}

interface ProjectsResponse {
  data: Project[];
}

export const getProjects = ({
  gbToken
}: GetProjectsParams): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<AxiosResponse<ProjectsResponse>> =>
  instance.get('/v1/private/projects', createConfig({ token: gbToken }));

interface GetProjectParams {
  organisationId: string;
  tenantId: string;
  projectId: string;
  gbToken: string;
}

export const getProject = ({
  organisationId,
  tenantId,
  projectId,
  gbToken
}: GetProjectParams): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<AxiosResponse<any>> =>
  instance.get(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}`,
    createConfig({ token: gbToken })
  );

interface GetProjectPhasesParams {
  projectId: string;
  gbToken: string;
}

interface GetPhasesResponse {
  data: Phase[];
}

export const getProjectPhases = ({
  projectId,
  gbToken
}: GetProjectPhasesParams): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<AxiosResponse<GetPhasesResponse>> =>
  instance.get(`/v1/private/projects/${projectId}/phases`, createConfig({ token: gbToken }));

interface GetProjectApartmentsAndRoomsParams {
  organisationId: string;
  tenantId: string;
  projectId: string;
  gbToken: string;
}

interface ApartmentsResponse {
  data: Apartment[];
}

export const getProjectApartmentsAndRooms = async ({
  organisationId,
  tenantId,
  projectId,
  gbToken
}: GetProjectApartmentsAndRoomsParams): Promise<AxiosResponse<ApartmentsResponse>> =>
  instance.get(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments-and-rooms`,
    createConfig({ token: gbToken })
  );

interface FetchApartmentInfoParams {
  organisationId: string;
  tenantId: string;
  projectId: string;
  gbToken: string;
  apartmentId: string;
  includeMessageStats: boolean;
}

interface FetchApartmentInfoApartment {
  apartmentId: number;
  ifcGuid: string;
  messageSummary: {
    massMessages: { totalCount: number; newCount: number };
    newCount: number;
    roomMessages: { totalCount: number; newCount: number };
    totalCount: number;
  };
  name: string;
  projectId: number;
  projectIfcguid: string;
}

interface FetchApartmentInfoResponse {
  data: FetchApartmentInfoApartment;
}

export const fetchApartmentInfo = async ({
  organisationId,
  tenantId,
  projectId,
  apartmentId,
  gbToken,
  includeMessageStats
}: FetchApartmentInfoParams): Promise<AxiosResponse<FetchApartmentInfoResponse>> =>
  instance.get(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartmentId}?rooms=true`,
    createConfig({ token: gbToken, params: { includeMessageStats } })
  );

interface GetSectionsForPhaseParams {
  organisationId: string;
  tenantId: string;
  gbToken: string;
  phaseId: string;
}

export const getSectionsForPhase = ({
  phaseId,
  organisationId,
  tenantId,
  gbToken
}: GetSectionsForPhaseParams): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<AxiosResponse<any>> =>
  instance.get(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/sections`,
    createConfig({ token: gbToken, params: { phaseId } })
  );

interface GetAttachmentsByProjectIdParams {
  organisationId: string;
  tenantId: string;
  gbToken: string;
  projectId: string;
}

export const getAttachmentsByProjectId = ({
  organisationId,
  tenantId,
  projectId,
  gbToken
}: GetAttachmentsByProjectIdParams): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<AxiosResponse<any>> =>
  instance.get(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/attachments/`,
    createConfig({ token: gbToken })
  );

interface GetDeadlinesParams {
  organisationId: string;
  tenantId: string;
  gbToken: string;
  projectId: string;
  apartmentId: string;
}

export const getDeadlines = async ({
  gbToken,
  organisationId,
  tenantId,
  apartmentId,
  projectId
}: GetDeadlinesParams) => {
  const response = await instance.get(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartmentId}/bundle-deadline-events`,
    createConfig({ token: gbToken })
  );
  return response.data.data;
};

// the response is different from the apartments used elsewhere....
interface GetApartmentsApartment {
  id: number;
  name: string;
}

interface GetApartmentsResponse {
  data: GetApartmentsApartment[];
}

interface GetApartmentsParams {
  organisationId: string;
  tenantId: string;
  gbToken: string;
  projectId: string;
}

export const getApartments = async ({
  gbToken,
  organisationId,
  tenantId,
  projectId
}: GetApartmentsParams): Promise<AxiosResponse<GetApartmentsResponse>> =>
  instance.get(
    `v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/cj/apartments/list`,
    createConfig({ token: gbToken })
  );
