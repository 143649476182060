import { SectionsStateAction, SectionsStateReducerType } from './interfaces';

const sectionsStateReducer: SectionsStateReducerType = (sectionsState, action) => {
  switch (action.type) {
    case SectionsStateAction.SET_SECTIONS:
      return {
        ...sectionsState,
        sections: action.data,
        fetchingSections: false,
        sectionsFetched: true
      };
    case SectionsStateAction.SET_FETCHING_SECTIONS:
      return {
        ...sectionsState,
        fetchingSections: action.data
      };
    case SectionsStateAction.SET_SECTIONS_FETCHED:
      return {
        ...sectionsState,
        sectionsFetched: action.data
      };
    case SectionsStateAction.SET_SELECTED_SECTION:
      return {
        ...sectionsState,
        selectedSection: action.data
      };
    case SectionsStateAction.SET_SELECTING_SECTION:
      return {
        ...sectionsState,
        selectingSection: action.data
      };
  }
};

export default sectionsStateReducer;
