import { Chip, Grid } from '@material-ui/core';
import { format } from 'date-fns';

import styles from './styles';
import useGetDeadlines from 'hooks/useGetDeadlines';
import { getDateFnsLocale } from 'i18n';
import useT from 'hooks/useT';
import useGetAttachments from 'hooks/useGetAttachments';
import { useHistory } from 'react-router-dom';
import useBasePath from 'hooks/useBasePath';
import { AttachmentType } from 'store/sectionsState/interfaces';
import { useContext, useState } from 'react';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { UserRight } from 'store/globalState/types';
import { Notification } from '@groupbuilderoy/gb-components-library';
import { useEffect } from 'react';
import { useGetCJ2Url } from 'components/Sections/MaterialSelections/MaterialSelections';

const MAX_COUNT = 2;

const Deadlines = () => {
  const classes = styles();
  const deadlines = useGetDeadlines();
  const { attachments } = useGetAttachments();
  const t = useT();
  const history = useHistory();
  const basePath = useBasePath();
  const { globalState } = useContext(GlobalStateContext);
  const [shownDeadlines, setDeadlines] = useState(deadlines.slice(0, MAX_COUNT));
  const CJ2Url = useGetCJ2Url();

  useEffect(() => {
    setDeadlines(deadlines.slice(0, MAX_COUNT));
  }, [deadlines]);
  const getSignableAttachments = () => {
    if (!attachments || !globalState.userRights?.check(UserRight.signDocument)) return [];

    return attachments.filter((a) => a.signable);
  };

  const signableAttachments = getSignableAttachments();

  const closeDeadline = (deadlineId: number) => {
    setDeadlines(shownDeadlines.filter((d) => d.id !== deadlineId));
  };

  return shownDeadlines.length > 0 || signableAttachments.length ? (
    <div className={classes.notifications}>
      {shownDeadlines.length &&
        shownDeadlines?.map((deadline, index) => {
          const date = new Date(deadline.endTime);
          return (
            <Notification
              style={{ margin: '0' }}
              id={deadline.id}
              key={`notification-${index}`}
              badgeCaption={t('warning')}
              caption={`${deadline.name}`}
              description={deadline.description || ''}
              deadline={format(date, 'YYY-MM-dd HH:mm', {
                locale: getDateFnsLocale()
              })}
              containerColor={'#FFFCF5'}
              borderColor={'#FEC84B'}
              badgeColors={{
                badgeColor: '#DC6803',
                captionColor: 'white',
                descriptionColor: '#B54708',
                containerColor: '#fdf3d4'
              }}
              actionCaption={t('selectMaterials')}
              actionOnClick={() => {
                window.open(CJ2Url, '_blank')?.focus();
              }}
              onClose={closeDeadline}
            />
          );
        })}
      {signableAttachments.slice(0, MAX_COUNT).map((attachment: AttachmentType, index: number) => {
        const { sections } = attachment;
        if (!sections) return null;

        return (
          <Grid
            key={`attachment-deadline-header-${index}`}
            item
            onClick={() => {
              history.push(
                `${basePath}/phase/${sections[0].phaseId}/section/${sections[0].sectionId}`
              );
            }}
          >
            <Chip
              label={`${t('attachments.sign')}: ${attachment.name}`}
              className={classes.linkChip}
            />
          </Grid>
        );
      })}
    </div>
  ) : null;
};

export default Deadlines;
