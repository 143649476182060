import { Grid, ListItem, Typography } from '@material-ui/core';
import { useContext } from 'react';
import { AttachmentFileType } from 'store/sectionsState/interfaces';
import { AttachmentViewerStateContext } from 'store/attachmentViewerState/AttachmentViewerStateProvider';
import { createAttachmentViewerState, getAttachmentFileType } from 'utils/sections';
import { AttachmentViewerStateAction } from 'store/attachmentViewerState/interfaces';
import useStyles from './styles';
import AttachmentIcon from '../AttachmentIcon/AttachmentIcon';
import { getDateFnsLocale } from 'i18n';
import { format } from 'date-fns';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { UserRight } from 'store/globalState/types';
import useT from 'hooks/useT';

// TODO: remove these dependencies and make our own ellips implementation
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { ListItemTag } from 'components/ListItemTag/ListItemTag';
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export default function AttachmentListItem({ attachment }: any) {
  const { filename, createdOn, description, signable } = attachment;
  const classes = useStyles();
  const { dispatch } = useContext(AttachmentViewerStateContext);
  const { globalState } = useContext(GlobalStateContext);
  const text = useT();

  const openModal = () => {
    const state = createAttachmentViewerState(attachment);
    const document = state.document;
    const type = document?.type;

    if (type === AttachmentFileType.OTHER) {
      window.open(attachment.link, '_blank');
      return;
    }

    dispatch({
      type: AttachmentViewerStateAction.OPEN,
      data: {
        ...state,
        showDownloadButton: type === AttachmentFileType.IMAGE
      }
    });
  };

  return (
    <ListItem className={classes.container} onClick={openModal} data-testid="attachment-item">
      {signable && globalState.userRights?.check(UserRight.signDocument) && (
        <ListItemTag text={text('attachments.sign')} />
      )}
      <Grid container direction="row" justifyContent="space-between" spacing={0}>
        <Grid item className={classes.iconHolder}>
          <AttachmentIcon type={getAttachmentFileType(attachment)} />
        </Grid>

        <Grid item className={classes.textHolder}>
          <ResponsiveEllipsis
            maxLine="1"
            text={filename}
            ellipsis="..."
            basedOn="words"
            className={classes.name}
          />

          <Typography className={classes.date}>
            {format(new Date(createdOn), 'MMM d, yyyy', {
              locale: getDateFnsLocale()
            })}
          </Typography>

          {description && (
            <ResponsiveEllipsis
              className={classes.description}
              maxLine="1"
              text={description}
              ellipsis="..."
              basedOn="words"
            />
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
}
