import { useTheme } from '@material-ui/core';
import { MessagingClientType, MessagingUI } from '@groupbuilderoy/messaging-client';
import { ApartmentsStateContext } from 'store/apartmentsState/ApartmentsStateProvider';
import { useContext } from 'react';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { ProjectsStateContext } from 'store/projectsState/ProjectsStateProvider';
import useStyles from './styles';
import { Apartment } from '@groupbuilderoy/messaging-client/dist/interfaces';
import i18next from 'i18next';
import Progress from 'components/Progress/Progress';

export default function Messaging() {
  const { apartmentsState } = useContext(ApartmentsStateContext);
  const { globalState } = useContext(GlobalStateContext);
  const { projectsState } = useContext(ProjectsStateContext);
  const apartment = apartmentsState.selectedApartment;
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {!apartmentsState.apartmentsFetched || !projectsState.selectedProject ? (
          <Progress />
        ) : (
          <MessagingUI
            token={String(globalState.token)}
            tenantId={String(globalState.startParameters.tenantId)}
            organisationId={String(globalState.startParameters.organisationId)}
            project={{
              id: Number(projectsState.selectedProject?.id),
              name: String(projectsState.selectedProject?.name)
            }}
            theme={theme}
            language={i18next.language}
            apartments={[{ id: apartment?.id, name: apartment?.name } as Apartment]}
            clientType={MessagingClientType.CustomerJourney}
          />
        )}
      </div>
    </div>
  );
}
