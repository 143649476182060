import { Divider, List, ListItem, makeStyles } from '@material-ui/core';
import { useContext } from 'react';
// import { useHistory, useParams } from "react-router-dom";
import { SurveysSection, SurveyType } from 'store/sectionsState/interfaces';
import SectionCard from '../SectionCard/SectionCard';
import { SectionsStateContext } from 'store/sectionsState/SectionsStateProvider';
import { SectionsStateAction } from 'store/sectionsState/interfaces';
import Survey from './Survey/Survey';

const useStyles = makeStyles({
  listItem: {
    cursor: 'pointer',
    paddingLeft: '0.45rem',
    marginBottom: '0.5rem'
  }
});

function Surveys(props: SurveysSection) {
  const classes = useStyles();
  // const history = useHistory();
  // const params = useParams();
  const { /*sectionsState,*/ dispatch } = useContext(SectionsStateContext);

  const handleSurveyClick = (survey: SurveyType) => {
    dispatch({ type: SectionsStateAction.SET_SELECTED_SECTION, data: props });
    // dispatch({
    //   type: AppStateAction.SET_RESOURCE,
    //   data: {
    //     type: Resources.SURVEY,
    //     data: survey,
    //   },
    // });
    // history.push(
    //   `${basePath(params, appState)}/section/${props.id}/resource/${survey.id}`
    // );
  };
  const SurveyList =
    props.surveys?.map((survey: SurveyType, index) => {
      return (
        <ListItem
          key={`announcement-${survey.id}`}
          className={classes.listItem}
          onClick={() => handleSurveyClick(survey)}
        >
          <Survey {...survey} />
          {props.surveys.length - 1 !== index && <Divider />}
        </ListItem>
      );
    }) || [];

  return (
    <SectionCard component={{ ...props, hideActionButton: true }}>
      <List disablePadding>{SurveyList}</List>
    </SectionCard>
  );
}

export default Surveys;
