import { Container, Typography, withStyles } from '@material-ui/core';
import React from 'react';

import styles from './styles';

type ErrorBoundaryStateType = {
  hasError: boolean;
  error?: Error;
  info: any;
};

type ErrorBoundaryPropsType = {
  classes: any;
};

class ErrorBoundary extends React.Component<ErrorBoundaryPropsType, ErrorBoundaryStateType> {
  constructor(props: ErrorBoundaryPropsType) {
    super(props);
    this.state = { hasError: false, error: undefined, info: {} };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: any) {
    // You can also log the error to an error reporting service
    this.setState({ error, info });
  }

  render() {
    const { classes } = this.props;

    if (this.state.error && this.state.error.message) {
      return (
        <Container className={classes.errorContainer}>
          <Typography variant="h1">{this.state.error?.message}</Typography>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default withStyles(styles, { withTheme: true })(ErrorBoundary);
