import i18n, { TFunction } from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resources from '../locales';

const parseLanguage = (language: string) => {
  return language.replace('_', '-');
};

const params = new URLSearchParams(window.location.search);
const langParam = params.get('lan');
const lng = langParam ? parseLanguage(langParam) : undefined;

const instance: Promise<TFunction> = i18n
  .use(LngDetector)
  .use(initReactI18next)
  .init({
    lng,
    resources,
    debug: false,
    fallbackLng: 'en',
    load: 'languageOnly',
    ns: 'main',
    defaultNS: 'main',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });

export default instance;
