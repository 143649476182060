import { useContext } from 'react';
import { useHistory } from 'react-router';
import { PhasesStateContext } from 'store/phasesState/PhasesStateProvider';
import { PhasesStateAction } from 'store/phasesState/interfaces';
import useBasePath from 'hooks/useBasePath';
import { IconKeys } from '@groupbuilderoy/gb-components-library';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { PhasesBar } from '@groupbuilderoy/gb-components-library';

function PhaseSelect() {
  const history = useHistory();
  const basePath = useBasePath();
  const { phasesState, dispatch } = useContext(PhasesStateContext);
  const { globalState } = useContext(GlobalStateContext);

  const handlePhaseChange = async (phaseId: number) => {
    const phase = phasesState.phases.find((phase) => phase.id === phaseId);
    if (phase) {
      history.push(`${basePath}/phase/${phase.id}`);
      dispatch({ type: PhasesStateAction.SET_SELECTED_PHASE, data: phase });
    }
  };
  const tabs = phasesState.phases.map((phase) => {
    return {
      id: phase.id,
      caption: phase.name,
      icon: IconKeys.construction,
      iconUrl: phase.icon
    };
  });
  const selectedTabIndex = tabs.findIndex((tab) => tab.id === phasesState.selectedPhase?.id);

  return (
    <>
      {tabs.length ? (
        <PhasesBar
          tabs={tabs}
          onClick={handlePhaseChange}
          initialSelectedTab={selectedTabIndex !== -1 ? selectedTabIndex : undefined}
          iconsFolderUrl={`/Icons/PhaseIcons/${globalState.tenantSettings?.cjCompanyResourcesFolder}`}
        />
      ) : null}
    </>
  );
}

export default PhaseSelect;
