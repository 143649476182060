export interface Phase {
  icon: string;
  orderinproject: number;
  projectId: number;
  id: number;
  name: string;
}

export interface PhasesState {
  phases: Phase[];
  fetchingPhases: boolean;
  phasesFetched: boolean;
  selectedPhase: Phase | null;
  selectingPhase: boolean;
}

export enum PhasesStateAction {
  SET_PHASES = 'SET_PHASES',
  SET_FETCHING_PHASES = 'SET_FETCHING_PHASES',
  SET_PHASES_FETCHED = 'SET_PHASES_FETCHED',
  SET_SELECTED_PHASE = 'SET_SELECTED_PHASE',
  SET_SELECTING_PHASE = 'SET_SELECTING_PHASE'
}

export interface SetPhasesAction {
  type: PhasesStateAction.SET_PHASES;
  data: Phase[];
}

export interface SetFetchingPhasesAction {
  type: PhasesStateAction.SET_FETCHING_PHASES;
  data: boolean;
}

export interface SetPhasesFetchedAction {
  type: PhasesStateAction.SET_PHASES_FETCHED;
  data: boolean;
}

export interface SetSelectedPhaseAction {
  type: PhasesStateAction.SET_SELECTED_PHASE;
  data: Phase | null;
}

export interface SetSelectingPhaseAction {
  type: PhasesStateAction.SET_SELECTING_PHASE;
  data: boolean;
}

export type PhasesStateActionType =
  | SetPhasesAction
  | SetFetchingPhasesAction
  | SetPhasesFetchedAction
  | SetSelectedPhaseAction
  | SetSelectingPhaseAction;

export type PhasesStateReducerType = (
  state: PhasesState,
  action: PhasesStateActionType
) => PhasesState;
