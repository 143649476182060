import { Grid, Typography } from '@material-ui/core';
import { AssignmentTurnedIn, Check, EventSeat, FiberNew, ThumbUp } from '@material-ui/icons';
import { ChecklistItem, IssueStatus } from 'axiosInstances/instances/CheckListResponse';
import useT from 'hooks/useT';
import { IssueState } from 'store/sectionsState/interfaces';
import styles from './styles';

interface IssuePropsType {
  status: IssueStatus;
  items?: ChecklistItem[];
}

export default function Issue({ status, items }: IssuePropsType) {
  const t = useT();
  const classes = styles();

  const itemTotalLength = items?.length || 0;

  const itemsWithStatus =
    items?.filter((item) => {
      return item.status === status.id;
    }).length || 0;

  if (itemsWithStatus === 0) return null;

  function getIcon(status: IssueState) {
    switch (status) {
      case IssueState.ACCEPTED:
        return <Check className={classes.black} />;
      case IssueState.APPROVED:
        return <ThumbUp className={classes.green} />;
      case IssueState.FIXED:
        return <AssignmentTurnedIn className={classes.blue} />;
      case IssueState.NEW:
        return <FiberNew className={classes.red} />;
      case IssueState.NO_ACTION_NEEDED:
        return <EventSeat className={classes.purple} />;
      default:
        return <Check />;
    }
  }

  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item className={classes.icon}>
        {getIcon(status.id)}
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Typography className={classes.percentagesText}>
              {itemsWithStatus > 0
                ? `${Math.round(
                    (itemsWithStatus / itemTotalLength) * 100
                  )}% (${itemsWithStatus}/${itemTotalLength})`
                : null}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.statusText}>{t(`checklist.${status.name}`)}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
