import { fetchApartmentInfo } from './../axiosInstances/instances/app';
import { useCallback, useContext, useEffect } from 'react';
import { ApartmentsStateContext } from 'store/apartmentsState/ApartmentsStateProvider';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { ProjectsStateContext } from 'store/projectsState/ProjectsStateProvider';
import { ApartmentsStateAction } from 'store/apartmentsState/interfaces';

const useApartmentInfoFetcher = () => {
  const { apartmentsState, dispatch } = useContext(ApartmentsStateContext);
  const { globalState } = useContext(GlobalStateContext);
  const { projectsState } = useContext(ProjectsStateContext);

  const { organisationId, tenantId } = globalState.startParameters;
  const projectId = projectsState.selectedProject?.id;
  const gbToken = globalState.token;
  const includeMessageStats = true;

  const doFetch = useCallback(async () => {
    if (
      apartmentsState.selectedApartment?.id &&
      !apartmentsState.selectedApartment.messageSummary &&
      organisationId &&
      tenantId &&
      gbToken &&
      !apartmentsState.apartmentInfoFetched
    ) {
      dispatch({
        type: ApartmentsStateAction.SET_SELECTING_APARTMENT,
        data: true
      });
      const response = await fetchApartmentInfo({
        organisationId,
        tenantId,
        projectId: String(projectId),
        apartmentId: String(apartmentsState.selectedApartment.id),
        gbToken,
        includeMessageStats
      });

      dispatch({
        type: ApartmentsStateAction.SET_APARTMENT_INFO_FETCHED,
        data: true
      });
      dispatch({
        type: ApartmentsStateAction.SET_SELECTED_APARTMENT,
        data: { ...response.data.data, id: response.data.data.apartmentId }
      });
    }
  }, [
    apartmentsState.selectedApartment,
    organisationId,
    tenantId,
    gbToken,
    includeMessageStats,
    projectId,
    dispatch,
    apartmentsState.apartmentInfoFetched
  ]);

  useEffect(() => {
    doFetch();
  }, [doFetch]);
};

export default useApartmentInfoFetcher;
