import { Grid, Modal, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useContext } from 'react';
import useStyles from './styles';
import ModalEmbed from './ModalEmbed';
import { AttachmentViewerStateContext } from 'store/attachmentViewerState/AttachmentViewerStateProvider';
import { AttachmentViewerStateAction } from 'store/attachmentViewerState/interfaces';
import useT from 'hooks/useT';
import { Button } from '@groupbuilderoy/gb-components-library';

export default function AttachmentViewer(): JSX.Element | null {
  const classes = useStyles();
  const { attachmentViewerState, dispatch } = useContext(AttachmentViewerStateContext);
  const t = useT();

  const handleClose = () => {
    dispatch({
      type: AttachmentViewerStateAction.CLOSE
    });
  };

  const download = () => {
    window.open(downloadLink, '_blank');
  };

  if (!attachmentViewerState.document) return null;

  const { document, downloadLink, open, titleKey, showDownloadButton } = attachmentViewerState;
  const { name } = document;
  return (
    <Modal open={open || false} onClose={handleClose}>
      <Grid container direction="column" className={classes.modalContainer}>
        <div className={classes.contentContainer}>
          <IconButton onClick={handleClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
          <div className={classes.header}>
            <Typography className={classes.title}>{t(String(titleKey))}</Typography>
            {name && <Typography className={classes.fileName}>{name}</Typography>}
          </div>
          <Grid item className={classes.grow}>
            <ModalEmbed document={document} />
          </Grid>
          {showDownloadButton && downloadLink?.length && (
            <Grid item className={classes.buttonsGrid}>
              <Button caption={t('attachments.download')} onClick={() => download()} />
            </Grid>
          )}
        </div>
      </Grid>
    </Modal>
  );
}
