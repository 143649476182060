import { ProjectsStateAction } from './../store/projectsState/interfaces';
import { getProjects } from './../axiosInstances/instances/app';
import { useCallback, useContext, useEffect } from 'react';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { ProjectsStateContext } from 'store/projectsState/ProjectsStateProvider';

const useProjectsFetcher = () => {
  const { globalState } = useContext(GlobalStateContext);
  const { projectsState, dispatch } = useContext(ProjectsStateContext);

  const fetchProjects = useCallback(async () => {
    if (!projectsState.projectsFetched && globalState.token) {
      dispatch({
        type: ProjectsStateAction.SET_FETCHING_PROJECTS,
        data: true
      });
      const { data } = await getProjects({ gbToken: globalState.token });

      if (data.data) {
        dispatch({ type: ProjectsStateAction.SET_PROJECTS, data: data.data });
        dispatch({
          type: ProjectsStateAction.SET_SELECTED_PROJECT,
          data:
            data.data.find(
              (project) => String(project.id) === globalState.startParameters.projectId
            ) || data.data[0]
        });
      } else throw new Error('No projects found.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.token, dispatch, projectsState.projectsFetched]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);
};

export default useProjectsFetcher;
