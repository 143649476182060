import { useContext } from 'react';
import Deadlines from 'components/Header/Deadlines/Deadlines';
import PhaseSelect from 'components/Header/PhaseSelect/PhaseSelect';
import useT from 'hooks/useT';
import { HeaderText } from '@groupbuilderoy/gb-components-library';
import ProjectPage from 'routes/Project/Project';
import { SectionsStateContext } from 'store/sectionsState/SectionsStateProvider';
import Progress from 'components/Progress/Progress';
import useStyles from './styles';

export default function Home() {
  const { sectionsState } = useContext(SectionsStateContext);
  const classes = useStyles();
  const t = useT();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        margin: '2rem auto',
        maxHeight: '100vh',
        paddingBottom: '4rem'
      }}
    >
      <Deadlines />
      <HeaderText heading={t('welcomeHeader')} description={t('mainSubheading')} />
      <PhaseSelect />
      <div className={classes.phaseContent}>
        {sectionsState.fetchingSections ? (
          <div className={classes.progressContainer}>
            <Progress />
          </div>
        ) : (
          <ProjectPage />
        )}
      </div>
    </div>
  );
}
