import { i18nextLng } from './StartUrlParameters';

export const logout = (organisationId: string | undefined): boolean => {
  const portalUrl = process.env.REACT_APP_PORTAL_URL
    ? process.env.REACT_APP_PORTAL_URL
    : global.env
    ? global.env.PORTAL_URL
    : '';

  if (!portalUrl || !portalUrl.length) {
    console.warn('Could not determine portal url for logout');
    return false;
  }

  const lang = window.localStorage.getItem(i18nextLng);
  window.localStorage.clear();

  if (lang) window.localStorage.setItem(i18nextLng, lang);

  window.location.href = `${portalUrl}/${organisationId}?logout=true`;

  return true;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
