import { useContext } from 'react';
import { ApartmentsStateContext } from '../../../../store/apartmentsState/ApartmentsStateProvider';
import { ProjectsStateContext } from '../../../../store/projectsState/ProjectsStateProvider';
import { ApartmentsStateAction } from 'store/apartmentsState/interfaces';
import { Select } from '@groupbuilderoy/gb-components-library';
import StartUrlParameters from 'utils/StartUrlParameters';
import useT from 'hooks/useT';
import { useTheme } from '@material-ui/core';

export default function ApartmentSelect(props: any) {
  const theme = useTheme();
  const t = useT();
  const { apartmentsState, dispatch } = useContext(ApartmentsStateContext);
  const { projectsState } = useContext(ProjectsStateContext);

  const handleApartmentChange = (apartmentId: number) => {
    const apartment = apartmentsState.apartments.find((apartment) => apartment.id === apartmentId);
    dispatch({
      type: ApartmentsStateAction.SET_APARTMENT_INFO_FETCHED,
      data: false
    });
    if (apartment) {
      dispatch({
        type: ApartmentsStateAction.SET_SELECTED_APARTMENT,
        data: apartment
      });
      window.localStorage.setItem(StartUrlParameters.apartmentId, apartmentId.toString());
    }
  };

  return (
    <Select
      caption={t('apartment')}
      initialValue={
        apartmentsState.apartments.some((a) => a.id === apartmentsState.selectedApartment?.id)
          ? apartmentsState.selectedApartment?.id || ''
          : apartmentsState.apartments[0]?.id || ''
      }
      itemList={apartmentsState.apartments}
      identifierKey={'id'}
      renderKey={'name'}
      onChange={handleApartmentChange}
      selectedItemColor={theme.palette.navBarSecondary?.main || 'white'}
      toLink={`/project/${projectsState.selectedProject?.id}/apartment`}
    />
  );
}
