import { AttachmentViewerStateAction, AttachmentViewerStateReducerType } from './interfaces';

const attachmentViewerStateReducer: AttachmentViewerStateReducerType = (
  attachmentViewerState,
  action
) => {
  switch (action.type) {
    case AttachmentViewerStateAction.OPEN:
      return {
        ...action.data,
        open: true
      };
    case AttachmentViewerStateAction.CLOSE:
      return {
        ...attachmentViewerState,
        open: false
      };
    case AttachmentViewerStateAction.SHOW_DOWNLOAD_BUTTON:
      return {
        ...attachmentViewerState,
        showDownloadButton: true
      };
    case AttachmentViewerStateAction.UPDATE_DOWNLOAD_LINK:
      return {
        ...attachmentViewerState,
        showDownloadButton: true,
        downloadLink: action.data
      };
    case AttachmentViewerStateAction.DOCUMENT_UPDATED:
      return {
        ...attachmentViewerState,
        documentUpdated: true
      };
    default:
      throw Error('Unknow action type');
  }
};

export default attachmentViewerStateReducer;
