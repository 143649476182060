import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    userInfo: {
      paddingRight: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(0),
        width: 'auto'
      }
    },
    username: {
      fontSize: theme.typography.subtitle2.fontSize,
      paddingRight: '0.5rem',
      [theme.breakpoints.between('sm', 'lg')]: {
        fontSize: theme.typography.overline.fontSize,
        paddingRight: '0.2rem'
      }
    },
    messagesIcon: {
      color: theme.palette.secondary.main,
      paddingRight: '0.5rem',
      [theme.breakpoints.between('sm', 'lg')]: {
        paddingRight: '0.2rem'
      }
    },
    icon: {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  })
);
