import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { customBreakpoints } from 'store/themes/base';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderRadius: '10px',
      // TODO: these magic numbers (450px, etc) should be calculated from header + footer
      height: 'calc(100vh)',

      [theme.breakpoints.down(customBreakpoints.mobileMenu)]: {
        height: 'calc(100vh - 370px)'
      },

      [theme.breakpoints.down('xs')]: {
        borderRadius: 0,
        margin: 0,
        height: 'calc(100vh - 410px)'
      },

      '& .message-list': {
        height: 'calc(100% - 10.5rem)'
      }
    },
    content: {
      position: 'relative',
      height: 'calc(100vh - 12rem)'
    },
    heading: {
      margin: '.5rem 0 1.5rem'
    },
    closeButton: {
      position: 'absolute',
      right: '.5rem',
      top: '.5rem',
      zIndex: 1
    }
  })
);
