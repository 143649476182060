export interface Project {
  id: number;
  name: string;
  canUseMessaging: boolean;
}

export interface ProjectsState {
  projects: Project[];
  fetchingProjects: boolean;
  selectedProject: null | Project;
  projectsFetched: boolean;
}

export enum ProjectsStateAction {
  SET_PROJECTS = 'SET_PROJECTS',
  SET_FETCHING_PROJECTS = 'SET_FETCHING_PROJECTS',
  SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT',
  SET_PROJECTS_FETCHED = 'SET_PROJECTS_FETCHED'
}

export interface SetProjectsAction {
  type: ProjectsStateAction.SET_PROJECTS;
  data: Project[];
}

export interface SetFetchingProjectsAction {
  type: ProjectsStateAction.SET_FETCHING_PROJECTS;
  data: boolean;
}

export interface SetSelectedProjectAction {
  type: ProjectsStateAction.SET_SELECTED_PROJECT;
  data: Project | null;
}

export interface SetProjectsFetchedAction {
  type: ProjectsStateAction.SET_PROJECTS_FETCHED;
  data: boolean;
}

export type ProjectsStateReducerType = (
  state: ProjectsState,
  action: ProjectsStateActionType
) => ProjectsState;

export type ProjectsStateActionType =
  | SetProjectsAction
  | SetFetchingProjectsAction
  | SetSelectedProjectAction
  | SetProjectsFetchedAction;
