import { Box, Grid, Modal, Typography } from '@material-ui/core';
import { useContext } from 'react';
import { ChatStateContext } from 'store/chatState/ChatStateProvider';
import { ChatStateAction } from 'store/chatState/actions';
import useStyles from './styles';
import ChatRoom from './ChatRoom/ChatRoom';
import useChatMessages from './useChatMessages';
import ThreadOverview from './ThreadOverview/ThreadOverview';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import useT from 'hooks/useT';

export default function Chat() {
  const refreshChat = useChatMessages();
  const classes = useStyles();
  const { chatState, dispatch } = useContext(ChatStateContext);
  const { globalState } = useContext(GlobalStateContext);
  const t = useT();

  const mainImage = globalState.tenantSettings?.customerJourney2_mainImage;

  const handleClose = () => {
    dispatch({
      type: ChatStateAction.SET_CHAT_VISIBILITY,
      data: false
    });
  };

  return (
    <Modal open={chatState.chatVisibility} onClose={handleClose}>
      <Grid
        direction="column"
        container
        wrap="nowrap"
        className={classes.chatContainer}
        spacing={0}
        alignItems="stretch"
      >
        <Grid item className={classes.headerImageContainer}>
          <img className={classes.headerImage} src={mainImage} alt="Open laptop" />
          <Box className={classes.headerTexts}>
            <Box className={classes.headerTextsWrapper}>
              <Typography variant="h3" className={classes.headerTitle}>
                {t('chat.messages')}
              </Typography>
              <Typography variant="body1" className={classes.headerDescription}>
                {t('chat.chatDescription')}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item className={classes.chatSection}>
          <ThreadOverview refreshChat={refreshChat} />
          <ChatRoom refreshChat={refreshChat} />
        </Grid>
      </Grid>
    </Modal>
  );
}
