import { StartUrlParametersInterface } from '../store/globalState/interfaces';

export enum StartUrlParameters {
  tenantId = 'tenantId',
  projectId = 'projectId',
  apartmentId = 'apartmentId',
  organisationId = 'organisationId',
  lan = 'lan',
  username = 'username',
  password = 'password',
  accessToken = 'accessToken',
  authToken = 'authToken',
  debug = 'debug'
}

export const StartUrlParametersArray: string[] = [
  StartUrlParameters.tenantId,
  StartUrlParameters.projectId,
  StartUrlParameters.apartmentId,
  StartUrlParameters.organisationId,
  StartUrlParameters.lan,
  StartUrlParameters.username,
  StartUrlParameters.password,
  StartUrlParameters.accessToken,
  StartUrlParameters.authToken,
  StartUrlParameters.debug
];

const unsafeParameters: string[] = [StartUrlParameters.username, StartUrlParameters.password];

export const i18nextLng = 'i18nextLng';

export const getStartParameters: () => StartUrlParametersInterface = () => {
  const urlParams = Object.fromEntries(new URLSearchParams(window.location.search.substring(1)));

  if (urlParams[StartUrlParameters.lan]) {
    window.localStorage.setItem(i18nextLng, urlParams[StartUrlParameters.lan]);
    delete urlParams[StartUrlParameters.lan];
  }

  const isDebug = !!urlParams[StartUrlParameters.debug];

  const newParams = !!Object.keys(urlParams).length && !isDebug;

  const localStorageParams: { [x: string]: string } | null = newParams
    ? null
    : StartUrlParametersArray.reduce((lsParams, cur) => {
        const param = window.localStorage.getItem(cur);
        if (param) lsParams[cur] = param;
        return lsParams;
      }, {} as { [x: string]: string });

  if (newParams && !isDebug) {
    StartUrlParametersArray.forEach((param) => window.localStorage.removeItem(param));
  }

  for (const [key, value] of Object.entries(urlParams)) {
    if (!StartUrlParametersArray.includes(key)) {
      delete urlParams[key];
    } else if (!unsafeParameters.includes(key) && key !== StartUrlParameters.debug)
      window.localStorage.setItem(key, value);
  }

  const startParams = { ...localStorageParams, ...urlParams };

  if (!isDebug) window.history.replaceState(null, '', `${window.location.pathname}`);
  else
    window.history.replaceState(
      null,
      '',
      `${window.location.pathname}?${new URLSearchParams(startParams).toString()}`
    );

  return startParams;
};

export const getLsParams: () => StartUrlParametersInterface = () => {
  return StartUrlParametersArray.reduce((lsParams, cur) => {
    const param = window.localStorage.getItem(cur);
    if (param) lsParams[cur] = param;
    return lsParams;
  }, {} as { [x: string]: string });
};

export default StartUrlParameters;
