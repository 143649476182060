import { Grid, ListItem } from '@material-ui/core';
import { Contact } from 'hooks/useGetContacts';
import { Avatar } from '@groupbuilderoy/gb-components-library';

import useStyles from './styles';

interface ContactProps extends Contact {}

const ContactComponent: React.FC<ContactProps> = ({
  id,
  jobTitle,
  name,
  email,
  phoneNumber,
  imageUrlSmall
}) => {
  const classes = useStyles();
  const details = [jobTitle];

  // Avatar component must receive an array of string, so parse details array to avoid passing nulls
  const parsedDetails = details.map((detail) => {
    return detail ? detail : '';
  });

  const links = [];
  if (phoneNumber) links.push(phoneNumber);
  if (email) links.push(email);
  return (
    <ListItem key={`section-contact-list-${id}`} className={classes.listItem}>
      <Grid container direction="row" spacing={2} alignItems="center" wrap="nowrap">
        <Grid item>
          <Avatar
            imgSrc={imageUrlSmall || undefined}
            name={name || ''}
            data={parsedDetails}
            links={links}
            imageSize={65}
          />
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default ContactComponent;
