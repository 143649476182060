import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { languages } from 'i18n';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { Icon, IconKeys } from '@groupbuilderoy/gb-components-library';
import { Select } from '@groupbuilderoy/gb-components-library';

export default function LanguageSelect() {
  const { i18n } = useTranslation();
  const changeLanguage = (code: string) => {
    i18n.changeLanguage(code).then((t) => {
      window.location.reload();
    });
  };
  const classes = useStyles();
  return (
    <>
      <Select
        caption=""
        onChange={changeLanguage}
        itemList={languages}
        identifierKey={'dateFnsLocaleKey'}
        renderKey={'title'}
        customRenderValue={
          <div className={classes.root}>
            <IconButton className={classes.icon}>
              <Icon iconKey={IconKeys.languages} />
            </IconButton>
            <Typography className={classes.langName}>
              {languages.find((l) => l.dateFnsLocaleKey === i18n.language)?.title}
            </Typography>
          </div>
        }
        hideIcon={true}
        initialValue={i18n.language || 'en'}
      />
    </>
  );
}
