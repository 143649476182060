import BackButton from 'components/BackButton/BackButton';
import createSection from './CreateSection';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import useStyles from './styles';
import Checklist from './Checklist/Checklist';
import Contacts from './Contacts/Contacts';
import Attachments from './Attachments/Attachments';
import { Section, SectionName, SectionsStateAction } from 'store/sectionsState/interfaces';
import { SectionsStateContext } from 'store/sectionsState/SectionsStateProvider';
import { StyledPaper } from '@groupbuilderoy/gb-components-library';

function getSectionPage(section: Section) {
  switch (section.type) {
    case SectionName.ISSUE_TRACKER:
      return <Checklist {...section} />;
    case SectionName.CONTACTS:
      return <Contacts {...section} />;
    case SectionName.ATTACHMENTS:
      return <Attachments {...section} />;
    default:
      return createSection(section, '80%');
  }
}

export default function SectionPage() {
  const classes = useStyles();
  const { sectionId } = useParams<{ sectionId?: string }>();
  const { sectionsState, dispatch } = useContext(SectionsStateContext);
  const back = () => {
    dispatch({ type: SectionsStateAction.SET_SELECTED_SECTION, data: null });
  };

  const section = sectionsState.sections.find((section) => String(section.id) === sectionId);

  return (
    <StyledPaper
      style={{ width: '100%' }}
      children={
        <div className={classes.section}>
          <BackButton callback={back} />
          {section ? getSectionPage(section) : null}
        </div>
      }
    />
  );
}
