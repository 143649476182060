import { AxiosRequestConfig } from 'axios';
import i18n from 'i18next';
export const baseUrl =
  process.env.REACT_APP_CORE_API_URL ||
  global.env.CORE_API_URL ||
  'https://api.beta.gbuilder.com/api';

interface ConfigOptions {
  token?: null | string;
  timeout?: number;
  //  eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers?: any;
  params?: Object;
}

interface PathVariables {
  organisationId?: string;
  tenantId?: string;
  projectId?: string;
}

export const createConfig: (options: ConfigOptions) => AxiosRequestConfig = ({
  token,
  timeout,
  headers = { 'accept-language': i18n.language || 'en' },
  params
}) => {
  return {
    baseURL: baseUrl,
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : undefined,
      'gb-client': 'CJ3'
    },
    timeout,
    params
  };
};

export const createPrivatePath = (
  { tenantId, organisationId, projectId }: PathVariables,
  apiVersion: string = 'v6'
) => {
  let path = `/${apiVersion}/private`;

  if (organisationId) path += `/organisations/${organisationId}`;
  if (tenantId) path += `/tenants/${tenantId}`;
  if (projectId) path += `/projects/${projectId}`;

  return path;
};
