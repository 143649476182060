import { Typography } from '@material-ui/core';

import useStyles from './styles';
import { SurveyType } from 'store/sectionsState/interfaces';

export default function Survey({ id, name, description }: SurveyType) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.header}>{name}</Typography>
      {!!description && <Typography className={classes.description}>{description}</Typography>}
    </div>
  );
}
