import { blue, green, grey, orange, purple } from '@material-ui/core/colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => {
  return createStyles({
    icon: {
      paddingLeft: '2rem',
      color: theme.palette.grey[700]
    },
    percentagesText: {
      color: theme.palette.common.black
    },
    statusText: {
      color: theme.palette.grey[500],
      fontSize: theme.typography.body2.fontSize
    },
    blue: {
      color: blue[800]
    },
    green: {
      color: green['A700']
    },
    red: {
      color: orange[500]
    },
    black: {
      color: grey[800]
    },
    purple: {
      color: purple[600]
    }
  });
});
