import React, { Dispatch, useMemo, useReducer } from 'react';
import sectionsStateReducer from './sectionsStateReducer';
import initialSectionsState from './initialSectionsState';
import { SectionsStateReducerType, SectionsState, SectionsStateActionType } from './interfaces';

export const SectionsStateContext = React.createContext<{
  sectionsState: SectionsState;
  dispatch: Dispatch<SectionsStateActionType>;
}>({
  sectionsState: initialSectionsState,
  dispatch: () => initialSectionsState
});

const { Provider } = SectionsStateContext;

interface SectionsStateProviderProps {
  children: React.ReactNode;
}

const SectionsStateProvider: React.FC<SectionsStateProviderProps> = ({ children }) => {
  const [sectionsState, dispatch] = useReducer<SectionsStateReducerType>(
    sectionsStateReducer,
    initialSectionsState
  );

  const contextValue = useMemo(() => ({ sectionsState, dispatch }), [sectionsState, dispatch]);

  return <Provider value={contextValue}>{children}</Provider>;
};

export default SectionsStateProvider;
