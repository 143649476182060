import { ProjectsState } from './interfaces';

const initialProjectsState: ProjectsState = {
  projects: [],
  selectedProject: null,
  fetchingProjects: false,
  projectsFetched: false
};

export default initialProjectsState;
