import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: '1.5rem',
      paddingLeft: '7rem',
      width: '100%',
      display: 'block'
    },
    section: {
      display: 'block',
      width: '100%',
      position: 'relative',
      zIndex: 1
    }
  })
);
