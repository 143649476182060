import SectionCard from '../SectionCard/SectionCard';

import { Divider, List, ListItem } from '@material-ui/core';
import { useContext } from 'react';
import { FolderType } from 'store/sectionsState/interfaces';
import { SectionsStateContext } from 'store/sectionsState/SectionsStateProvider';
import useT from 'hooks/useT';

export default function Folder(props: FolderType) {
  const t = useT();
  const { sectionsState } = useContext(SectionsStateContext);
  const childrenSections = sectionsState.sections.filter(
    (section) => section.parentSectionId === String(props.id)
  );

  const folderListItems = childrenSections.map((section, index) => (
    <div key={`folder-${props.id}-list-item-${index}`}>
      <ListItem>{section.name}</ListItem>
      {index !== childrenSections.length - 1 && <Divider variant="middle" />}
    </div>
  ));

  return (
    <SectionCard component={{ ...props, hideActionButton: true }} buttonText={t('view')}>
      <List disablePadding>{folderListItems}</List>
    </SectionCard>
  );
}
