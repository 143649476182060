import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { customBreakpoints } from 'store/themes/base';

export const useStyles = (screenWidth?: number) => {
  return makeStyles((theme: Theme) =>
    createStyles({
      root: {
        paddingTop: '1.5rem',
        paddingLeft: '7rem',
        width: '100%',
        display: 'block'
      },
      pageHeader: {
        fontSize: theme.typography.h5.fontSize,
        fontWeight: theme.typography.fontWeightBold
      } as CSSProperties,
      headerCell: {
        borderBottom: `1px solid #344054`,
        '&>span>button': {
          color: '#344054',
          '&:hover': {
            color: `#344054 !important`
          }
        }
      },
      tableCell: {
        '& > div': {
          // Mui datatables are responsive to small screens, table cells should not have a max width for those cases
          maxWidth: screenWidth && screenWidth < customBreakpoints.mobileMenu ? 'auto' : '100px',
          color: '#667085'
        }
      },
      tableCellDescription: {
        '& > div': {
          // Mui datatables are responsive to small screens, table cells should not have a max width for those cases
          maxWidth: screenWidth && screenWidth < customBreakpoints.mobileMenu ? 'auto' : '250px',
          color: '#667085'
        }
      },
      imageCell: { maxWidth: '100px' }
    })
  );
};
