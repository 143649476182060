import { GlobalStateAction } from 'store/globalState/interfaces';
import { getTenantSettings } from './../axiosInstances/instances/app';
import { GlobalStateContext } from './../store/globalState/GlobalStateProvider';
import { useCallback, useContext, useEffect } from 'react';

const useTenantSettingsFetcher = (): void => {
  const { globalState, dispatch } = useContext(GlobalStateContext);
  const { tenantId, organisationId } = globalState.startParameters;

  const fetchTenantSettings = useCallback(async () => {
    if (tenantId && organisationId && globalState.token && !globalState.tenantSettingsFetched) {
      dispatch({
        type: GlobalStateAction.SET_FETCHING_TENANT_SETTINGS,
        data: true
      });
      const tenantSettingsResponse = await getTenantSettings(
        organisationId,
        tenantId,
        globalState.token
      );

      dispatch({
        type: GlobalStateAction.SET_TENANT_SETTINGS,
        data: tenantSettingsResponse.data.data
      });
    }
  }, [tenantId, organisationId, globalState.token, dispatch, globalState.tenantSettingsFetched]);

  useEffect(() => {
    fetchTenantSettings();
  }, [fetchTenantSettings]);
};

export default useTenantSettingsFetcher;
