import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { MdOutlinePersonOutline } from 'react-icons/md';
import useT from 'hooks/useT';
import React, { useContext } from 'react';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { GlobalStateAction } from 'store/globalState/interfaces';
import { logout } from 'utils/utils';
import styles from './styles';

export default function User() {
  const t = useT();
  const classes = styles();
  const { globalState, dispatch } = useContext(GlobalStateContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch({ type: GlobalStateAction.SET_TOKEN, data: null });
    handleClose();
    logout(globalState.startParameters.organisationId);
  };

  return (
    <>
      <IconButton
        edge="end"
        aria-label={t('user.accountOfCurrentUser')}
        aria-haspopup="true"
        color="inherit"
        className={classes.icon}
        onClick={handleClick}
      >
        {globalState.user?.name ? (
          <Typography className={classes.username}>{globalState.user.name}</Typography>
        ) : null}
        <MdOutlinePersonOutline size="30" />
        <ArrowDropDown fontSize="small" />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose}>{t("user.profile")}</MenuItem>
        <MenuItem onClick={handleClose}>{t("user.account")}</MenuItem> */}
        <MenuItem data-testid="logout-button" onClick={handleLogout}>
          {t('user.logout')}
        </MenuItem>
      </Menu>
    </>
  );
}
