import { fetchChecklist } from 'axiosInstances';
import { Checklist } from 'axiosInstances/instances/CheckListResponse';
import { useContext, useEffect, useState } from 'react';
import { ApartmentsStateContext } from 'store/apartmentsState/ApartmentsStateProvider';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';

export default function useGetChecklistItems() {
  const { apartmentsState } = useContext(ApartmentsStateContext);
  const { globalState } = useContext(GlobalStateContext);
  const gbToken = globalState.token;
  const apartmentId = apartmentsState.selectedApartment?.id;
  const [checklistItems, setChecklistItems] = useState<Checklist | null>(null);
  const [fetchingChecklistItems, setFetchingChecklistItem] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (gbToken && apartmentId) {
        setFetchingChecklistItem(true);
        const checklistItemsResponse = await fetchChecklist({
          apartmentId: String(apartmentId),
          gbToken
        });
        setChecklistItems(checklistItemsResponse.data.data);
        setFetchingChecklistItem(false);
      }
    })();
  }, [apartmentId, gbToken]);

  return { checklistItems, fetchingChecklistItems };
}
