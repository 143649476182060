import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => {
  const paddingLeft = theme.typography.pxToRem(7);

  return createStyles({
    container: {
      position: 'relative',
      padding: '8px 4px',
      cursor: 'pointer',
      transition: 'border-color .25s',
      borderBottom: '1px solid ' + theme.palette.grey[400],

      '&:not(:first-child)': {
        paddingTop: '18px'
      },
      '&:hover': {
        borderBottom: '1px solid ' + theme.palette.primary.main
      }
    },
    iconHolder: {
      width: '2rem',

      '> svg': {
        marginRight: '.5rem'
      }
    },
    textHolder: {
      width: 'calc(100% - 2rem)'
    },
    name: {
      paddingLeft,
      fontSize: theme.typography.subtitle1.fontSize
    },
    date: {
      color: theme.palette.grey[800],
      fontSize: theme.typography.subtitle2.fontSize,
      lineHeight: 2,
      paddingLeft
    },
    ribbon: {
      position: 'absolute',
      width: 'auto',
      height: '20px',
      padding: '0 .3rem',
      boxSizing: 'border-box',
      pointerEvents: 'none',
      backgroundColor: theme.palette.primary.light,
      textTransform: 'uppercase',
      right: '-23px',
      color: '#fff',
      top: '5px',
      zIndex: 1,
      backgroundImage: 'linear-gradient(90deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.1))',
      lineHeight: '19px',
      textAlign: 'center',

      '&::before': {
        content: '""',
        position: 'absolute',
        height: 0,
        width: 0,
        right: 0,
        borderRight: '7px solid transparent',
        borderTop: '5px solid ' + theme.palette.primary.main,
        bottom: ' -5px'
      }
    },
    signTag: {
      fontSize: '.75rem'
    },
    description: {
      color: theme.palette.common.black,
      fontSize: theme.typography.subtitle2.fontSize,
      paddingLeft
    }
  });
});
