import { TOptions } from 'i18next';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { useContext } from 'react';
import { t } from '../utils/translation';

const useT = () => {
  const { globalState } = useContext(GlobalStateContext);

  return (translationString: string, options?: TOptions) =>
    t(translationString, globalState.startParameters.organisationId || 'gbuilder', options);
};

export default useT;
