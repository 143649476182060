// hooks
import { useContext } from 'react';

import styles from 'components/Sections/styles';
import createSection from 'routes/SectionView/CreateSection';
import { SectionsStateContext } from 'store/sectionsState/SectionsStateProvider';

export default function HomePage() {
  const classes = styles();
  const { sectionsState } = useContext(SectionsStateContext);

  return <div className={classes.sections}>{sectionsState.sections.map(createSection)}</div>;
}
