import { useContext, useEffect } from 'react';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { getStartParameters } from 'utils/StartUrlParameters';
import { GlobalStateAction } from '../store/globalState/interfaces';

const useSetupApp = (): void => {
  const { dispatch } = useContext(GlobalStateContext);

  useEffect(() => {
    dispatch({ type: GlobalStateAction.SETUP, data: getStartParameters() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useSetupApp;
