import { login } from 'axiosInstances';
import { StartUrlParameters } from './../utils/StartUrlParameters';
import { useContext, useEffect } from 'react';
import { GlobalStateAction } from '../store/globalState/interfaces';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { tokenExchange } from 'axiosInstances';

const LS_USER = 'USER';

const useLogin = (): void => {
  const { globalState, dispatch } = useContext(GlobalStateContext);

  const { password, username, authToken, accessToken, tenantId, organisationId } =
    globalState.startParameters;

  useEffect(() => {
    if (tenantId) {
      const doLogin = async () => {
        if (password && username) {
          const response = await login(username, password, tenantId);
          dispatch({
            type: GlobalStateAction.SET_TOKEN,
            data: response.data.token
          });
          window.localStorage.setItem(StartUrlParameters.authToken, response.data.token);
          window.localStorage.setItem(LS_USER, JSON.stringify(response.data.data));
        } else if (accessToken && organisationId) {
          const response = await tokenExchange(organisationId, tenantId, accessToken);
          dispatch({
            type: GlobalStateAction.SET_TOKEN,
            data: response.data.data.token
          });
          window.localStorage.setItem(StartUrlParameters.authToken, response.data.data.token);
          window.localStorage.setItem(LS_USER, JSON.stringify(response.data.data.user));
        } else if (authToken) {
          dispatch({
            type: GlobalStateAction.SET_TOKEN,
            data: authToken
          });
        }

        dispatch({
          type: GlobalStateAction.SET_USER,
          data: JSON.parse(window.localStorage.getItem(LS_USER) || '{}')
        });
      };
      doLogin();
    }
  }, [password, username, accessToken, authToken, tenantId, organisationId, dispatch]);
};

export default useLogin;
