import useStyles from './styles';

interface ImagePresenterProps {
  src: string;
  alt: string;
  tableCell?: boolean;
}

const ImagePresenter = ({ src, alt, tableCell }: ImagePresenterProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.imagePresenter}>
      <img
        style={
          tableCell
            ? // When using images on tables, limit their size to 75px, this value could be changed to be passed as prop
              { minWidth: '75px', maxHeight: '75px', maxWidth: '75px' }
            : { maxWidth: '100%', maxHeight: 'calc(100vh - 25rem)' }
        }
        alt={alt}
        src={src}
        onError={(e) => {
          if (e.currentTarget.src !== require('./brokenImage.png')) {
            e.currentTarget.src = require('./brokenImage.png');
          }
        }}
      />
    </div>
  );
};

export default ImagePresenter;
