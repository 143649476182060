import { SvgIcon, Typography, useTheme } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import DateSquare from 'components/DateSquare/DateSquare';
import { ChatRoomThreadType, ChatStatusType } from '../../../../types';
import styles from './styles';

import { useContext } from 'react';
import { ChatStateContext } from 'store/chatState/ChatStateProvider';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { ChatStateAction } from 'store/chatState/actions';
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export default function RoomThread(props: ChatRoomThreadType) {
  const classes = styles();
  const theme = useTheme();
  const { dispatch } = useContext(ChatStateContext);
  const { messages, room } = props;

  // TODO find the latest one (not sure if it is last or first in array)
  // TODO this will get removed when room gets the property of "latestMessage" attached directly
  const latestMessage = messages[0];

  const onClick = () => {
    dispatch({ type: ChatStateAction.SET_CHAT_ROOM, data: props });
  };

  return (
    <div className={classes.messageRoot} onClick={onClick}>
      <DateSquare
        showYear
        millisecondsTime={latestMessage.sendTime}
        color={
          latestMessage.discussionEnd === ChatStatusType.CLOSED
            ? theme.palette.grey[600]
            : theme.palette.primary.main
        }
      />
      <div className={classes.messagesContainer}>
        <Typography className={classes.messageTitle}>{room.name}</Typography>
        <ResponsiveEllipsis
          maxLine="2"
          text={latestMessage.content}
          ellipsis="..."
          basedOn="words"
        />
        <Typography className={classes.messageSender}>{latestMessage.userName}</Typography>
      </div>
      <SvgIcon className={classes.messageArrow}>
        <ArrowForwardIos />
      </SvgIcon>
    </div>
  );
}
