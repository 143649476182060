import { ChatStateType } from './types';

const initialChatState: ChatStateType = {
  threads: [],
  newChat: false,
  bulletins: [],
  isRefreshing: false,
  lastRefresh: 0,
  chatVisibility: false
};

export default initialChatState;
