import { ApartmentsStateReducerType, ApartmentsStateAction } from './interfaces';

const apartmentsStateReducer: ApartmentsStateReducerType = (apartmentsState, action) => {
  switch (action.type) {
    case ApartmentsStateAction.SET_APARTMENTS:
      return {
        ...apartmentsState,
        apartments: action.data,
        fetchingApartments: false
      };
    case ApartmentsStateAction.SET_FETCHING_APARTMENTS:
      return {
        ...apartmentsState,
        fetchingApartments: action.data
      };
    case ApartmentsStateAction.SET_SELECTED_APARTMENT:
      return {
        ...apartmentsState,
        selectedApartment: action.data,
        selectingApartment: false
      };
    case ApartmentsStateAction.SET_SELECTING_APARTMENT:
      return {
        ...apartmentsState,
        selectingApartment: action.data
      };
    case ApartmentsStateAction.SET_APARTMENTS_FETCHED:
      return {
        ...apartmentsState,
        apartmentsFetched: action.data
      };
    case ApartmentsStateAction.SET_APARTMENT_INFO_FETCHED:
      return {
        ...apartmentsState,
        apartmentInfoFetched: action.data
      };
    default:
      throw new Error('Bad reducer action.');
  }
};

export default apartmentsStateReducer;
