import { AttachmentType, Section } from 'store/sectionsState/interfaces';
import SectionCard from '../SectionCard/SectionCard';
import useGetAttachments from '../../../hooks/useGetAttachments';
import Progress from 'components/Progress/Progress';
import AttachmentListItem from 'components/Attachments/AttachmentList/AttachmentListItem';
import useT from 'hooks/useT';
import { Alert } from '@material-ui/lab';
import useStyles from '../../../styles';

const MAX_COUNT = 4;

const Attachments = (section: Section) => {
  const { loading, attachments, error } = useGetAttachments(String(section.id));
  const text = useT();
  const classes = useStyles();

  const content = () => {
    if (loading) {
      return <Progress />;
    } else if (!attachments?.length || error) {
      const severity = error ? 'error' : 'info';
      const messageKey = error ? 'attachments.fetchFailed' : 'attachments.noAttachments';
      return (
        <Alert variant="outlined" severity={severity} className={classes.tileAlert}>
          {text(messageKey)}
        </Alert>
      );
    }

    return attachments.slice(0, MAX_COUNT).map((attachment: AttachmentType, index: number) => {
      return <AttachmentListItem key={`attachment-${index}`} attachment={attachment} />;
    });
  };

  return (
    <SectionCard component={{ ...section, hideActionButton: attachments.length <= MAX_COUNT }}>
      {content()}
    </SectionCard>
  );
};

export default Attachments;
