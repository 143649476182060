import useStyles from './styles';

import {
  Description,
  InsertPhotoOutlined,
  PictureAsPdfOutlined,
  AttachmentOutlined,
  Create
} from '@material-ui/icons';
import { AttachmentFileType } from 'store/sectionsState/interfaces';

export interface AttachmentIconProps {
  type: AttachmentFileType;
}

export default function AttachmentIcon({ type }: AttachmentIconProps) {
  const classes = useStyles();

  switch (type) {
    case AttachmentFileType.SIGNABLE:
    case AttachmentFileType.SIGNED:
      const iconClass =
        type === AttachmentFileType.SIGNED ? classes.icon : classes.iconActionNeeded;
      return <Create fontSize="medium" className={iconClass} data-testid="sign-icon" />;
    case AttachmentFileType.IMAGE:
      return (
        <InsertPhotoOutlined fontSize="medium" className={classes.icon} data-testid="image-icon" />
      );
    case AttachmentFileType.PDF:
      return (
        <PictureAsPdfOutlined fontSize="medium" className={classes.icon} data-testid="pdf-icon" />
      );
    case AttachmentFileType.VIDEO:
      return <Description fontSize="medium" className={classes.icon} data-testid="video-icon" />;
    default:
      return <AttachmentOutlined className={classes.icon} data-testid="default-icon" />;
  }
}
