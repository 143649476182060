import { AttachmentFileType, AttachmentType } from '../sectionsState/interfaces';

export interface AttachmentViewerState {
  attachment?: AttachmentType | null;
  document: AttachmentViewerDocument | null;
  titleKey?: string;
  open?: boolean;
  downloadLink?: string;
  showDownloadButton?: boolean;
  documentUpdated?: boolean;
}

export interface AttachmentViewerDocument {
  type: AttachmentFileType;
  url: string;
  name?: string;
  mimeType?: string;
  xfdf?: string | '';
  id?: number;
  signed?: boolean;
}

export enum AttachmentViewerStateAction {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  DOCUMENT_UPDATED = 'DOCUMENT_UPDATED',
  UPDATE_DOWNLOAD_LINK = 'UPDATE_DOWNLOAD_LINK',
  SHOW_DOWNLOAD_BUTTON = 'SHOW_DOWNLOAD_BUTTON'
}
interface OpenAction {
  type: AttachmentViewerStateAction.OPEN;
  data: AttachmentViewerState;
}
interface CloseAction {
  type: AttachmentViewerStateAction.CLOSE;
}

interface ShowDownloadButton {
  type: AttachmentViewerStateAction.SHOW_DOWNLOAD_BUTTON;
  data: AttachmentViewerDocument;
}
interface UpdateDownloadLinkAction {
  type: AttachmentViewerStateAction.UPDATE_DOWNLOAD_LINK;
  data: string;
}
interface DocumentUpdatedAction {
  type: AttachmentViewerStateAction.DOCUMENT_UPDATED;
}

export type AttachmentViewerStateActionType =
  | OpenAction
  | CloseAction
  | ShowDownloadButton
  | UpdateDownloadLinkAction
  | DocumentUpdatedAction;

export type AttachmentViewerStateReducerType = (
  state: AttachmentViewerState,
  action: AttachmentViewerStateActionType
) => AttachmentViewerState;
