import en from "./en/main.json";
import enGB from "./en-GB/main.json";
import fi from "./fi/main.json";
import nl from "./nl/main.json";
import fr from "./fr/main.json";
import de from "./de/main.json";
import sv from "./sv/main.json";
import ru from "./ru/main.json";
import nb from "./nb/main.json";

const languages = { en, enGB, fi, nb, nl, fr, de, sv, ru };

export default languages;
