import { Select } from '@groupbuilderoy/gb-components-library';
import { useTheme } from '@material-ui/core';
import useT from 'hooks/useT';
import { useContext } from 'react';
import { ApartmentsStateContext } from 'store/apartmentsState/ApartmentsStateProvider';
import { ApartmentsStateAction } from 'store/apartmentsState/interfaces';
import { ProjectsStateAction } from 'store/projectsState/interfaces';
import { ProjectsStateContext } from 'store/projectsState/ProjectsStateProvider';
import StartUrlParameters from 'utils/StartUrlParameters';

export default function ProjectSelect(props: any) {
  const theme = useTheme();
  const t = useT();
  const { projectsState, dispatch } = useContext(ProjectsStateContext);
  const { dispatch: dispatchApartmentState } = useContext(ApartmentsStateContext);

  const handleProjectChange = async (projectId: number) => {
    const project = projectsState.projects.find((project) => project.id === projectId);
    if (project) {
      dispatch({
        type: ProjectsStateAction.SET_SELECTED_PROJECT,
        data: project
      });
      window.localStorage.setItem(StartUrlParameters.projectId, projectId.toString());
      dispatchApartmentState({
        type: ApartmentsStateAction.SET_APARTMENTS_FETCHED,
        data: false
      });
    }
  };

  return (
    <>
      <Select
        caption={t('project')}
        initialValue={projectsState.selectedProject?.id || projectsState.projects[0]?.id || ''}
        itemList={projectsState.projects}
        identifierKey={'id'}
        renderKey={'name'}
        onChange={handleProjectChange}
        selectedItemColor={theme.palette.navBarSecondary?.main || 'white'}
        toLink={'/project'}
      />
    </>
  );
}
