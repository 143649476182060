import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => {
  return createStyles({
    contact: {
      marginBottom: '0.25rem',

      '&:not(:last-child) > li': {
        borderBottom: '1px solid ' + theme.palette.grey[400]
      }
    }
  });
});
