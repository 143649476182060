import { useState, useEffect, useContext } from 'react';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { useProjectSettingsFetcher } from './useProjectSettingsFetcher';

interface ShowMessagingProps {
  messagingEnabled: boolean;
  newMessagingEnabled: boolean;
}

/**
 * useMessaging hook
 *
 * @returns - ShowMessagingProps
 */
export const useShowMessaging = (): ShowMessagingProps => {
  const { globalState } = useContext(GlobalStateContext);
  const { tenantSettingsFetched, tenantSettings } = globalState;
  const { loading: loadingProjectSettings, settings: projectSettings } =
    useProjectSettingsFetcher();
  const [messagingEnabled, setMessagingEnabled] = useState<boolean>(false);
  const [newMessagingEnabled, setNewMessagingEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (loadingProjectSettings || !tenantSettingsFetched || !tenantSettings.useMessaging) return;

    let newMessagingProjectSetting: boolean | null = null;
    let newMessagingEnabled: boolean = false;

    for (const setting of projectSettings) {
      if (setting.key !== 'useNewMessaging') continue;

      newMessagingProjectSetting = setting.value as boolean | null;
    }

    if (newMessagingProjectSetting !== null) newMessagingEnabled = newMessagingProjectSetting;
    else newMessagingEnabled = tenantSettings.useNewMessaging as boolean;

    setMessagingEnabled(true);
    setNewMessagingEnabled(newMessagingEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProjectSettings, tenantSettingsFetched]);

  return { messagingEnabled, newMessagingEnabled };
};
