import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      position: 'relative',
      background: theme.palette.common.white,
      padding: theme.spacing(0),
      margin: '2rem auto',
      width: '75%',
      height: 'calc(100vh - 4rem)',
      '@media screen and (max-width: 480px)': {
        height: '100vh',
        width: '100%',
        margin: 0
      }
    },
    contentContainer: {
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    grow: {
      height: '100%',
      '& .buttons-grid': {
        marginTop: '2rem'
      },
      borderTop: '1px solid rgba(0, 0, 0, .25)',
      borderBottom: '1px solid rgba(0, 0, 0, .25)',
      padding: '1rem'
    },
    grow2: {
      height: '60%',
      '& .buttons-grid': {
        marginTop: '2rem'
      },
      borderTop: '1px solid rgba(0, 0, 0, .25)',
      borderBottom: '1px solid rgba(0, 0, 0, .25)',
      padding: '1rem'
    },
    header: {
      margin: '1rem auto',
      width: '100%',
      textAlign: 'center',
      paddingBottom: '1rem',
      alignSelf: 'flex-start'
    },
    title: {
      fontSize: theme.typography.h3.fontSize
    },
    fileName: {
      fontSize: theme.typography.subtitle1.fontSize,
      color: theme.palette.text.secondary
    },
    buttonsGrid: {
      margin: '1rem auto',
      textAlign: 'center'
    },
    downloadButton: {
      fontSize: theme.typography.subtitle1.fontSize
    },
    paper: {
      maxWidth: 'calc(100vw - 3rem)'
    },
    pdfViewer: {
      height: 'calc(100vh - 20.3rem)',

      '&.loading': {
        display: 'none'
      }
    },
    downloadSignable: {
      textAlign: 'center',
      marginTop: '1rem',

      '&.loading': {
        display: 'none'
      }
    },
    closeButton: {
      width: '2rem',
      height: '2rem',
      position: 'absolute',
      right: 0,
      margin: '1rem'
    },
    imagePresenter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%'
    }
  })
);
