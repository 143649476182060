import { Grid, Typography } from '@material-ui/core';
import { AnnouncementType } from 'store/sectionsState/interfaces';

import useStyles from './styles';

export default function Announcement({ name, description }: AnnouncementType) {
  const classes = useStyles();
  return (
    <Grid container direction="column" alignItems="flex-start" className={classes.root}>
      <Grid item>
        <Typography className={classes.header}>{name}</Typography>
      </Grid>

      {!!description && (
        <Grid item>
          <Typography className={classes.description}>{description}</Typography>
        </Grid>
      )}
    </Grid>
  );
}
