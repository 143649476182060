import { ProjectsStateAction, ProjectsStateReducerType } from './interfaces';

const projectsStateReducer: ProjectsStateReducerType = (projectsState, action) => {
  switch (action.type) {
    case ProjectsStateAction.SET_PROJECTS:
      return {
        ...projectsState,
        projects: action.data,
        fetchingProjects: false,
        projectsFetched: true
      };
    case ProjectsStateAction.SET_FETCHING_PROJECTS:
      return {
        ...projectsState,
        fetchingProjects: action.data
      };
    case ProjectsStateAction.SET_SELECTED_PROJECT:
      return {
        ...projectsState,
        selectedProject: action.data
      };
    case ProjectsStateAction.SET_PROJECTS_FETCHED:
      return {
        ...projectsState,
        projectsFetched: action.data
      };
    default:
      throw new Error(`Bad reducer action.`);
  }
};

export default projectsStateReducer;
