import {
  SetChatBulletinsAction,
  SetChatIsRefreshingAction,
  SetChatLastRefreshTimeAction,
  SetChatRoomAction,
  SetChatThreadsAction,
  SetNewChatAction,
  SetChatVisibilityAction,
  SendMessageToThreadAction
} from './chat';

export * from './chat';

export enum ChatStateAction {
  SET_NEW_CHAT = 'SET_NEW_CHAT',
  SET_THREADS = 'SET_THREADS',
  SET_BULLETINS = 'SET_BULLETINS',
  SET_CHAT_ROOM = 'SET_CHAT_ROOM',
  SET_IS_REFRESHING = 'SET_IS_REFRESHING',
  SET_LAST_REFRESH_TIME = 'SET_LAST_REFRESH_TIME',
  SET_CHAT_VISIBILITY = 'SET_CHAT_VISIBILITY',
  SEND_MESSAGE_TO_THREAD = 'SEND_MESSAGE_TO_THREAD'
}

export type ChatStateActionType =
  | SetNewChatAction
  | SetChatRoomAction
  | SetChatThreadsAction
  | SetChatIsRefreshingAction
  | SetChatLastRefreshTimeAction
  | SetChatBulletinsAction
  | SetChatVisibilityAction
  | SendMessageToThreadAction;
