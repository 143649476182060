import { useStyles } from './styles';
import { Grid, Typography } from '@material-ui/core';
import { IssueTrackerType } from 'store/sectionsState/interfaces';
import useGetChecklistItems from 'components/Sections/IssueTracker/useGetChecklistItems';
import useT from 'hooks/useT';
import MUIDataTable, { TableBody } from 'mui-datatables';
import Progress from 'components/Progress/Progress';
import DateSquare from 'components/DateSquare/DateSquare';
import ImagePresenter from 'components/Attachments/AttachmentViewer/ImagePresenter';
import useWindowDimensions from 'hooks/useWindowDimensions';

export default function ChecklistSectionPage(props: IssueTrackerType) {
  const { width } = useWindowDimensions();
  const classes = useStyles(width)();
  const { checklistItems: checklist, fetchingChecklistItems } = useGetChecklistItems();
  const t = useT();

  const defaultCellProps = {
    setCellHeaderProps: () => {
      return {
        className: classes.headerCell
      };
    },
    setCellProps: () => {
      return {
        className: classes.tableCell
      };
    }
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography className={classes.pageHeader}>{props.name}</Typography>
      </Grid>
      <Grid item>
        {checklist?.items ? (
          <MUIDataTable
            title={''}
            columns={[
              {
                label: t('table.created'),
                name: 'created',
                options: {
                  ...defaultCellProps,
                  filter: false,
                  customBodyRenderLite: (dataIndex) => {
                    return (
                      <DateSquare
                        millisecondsTime={checklist.items[dataIndex].created}
                        textColor="var(--color-primary)"
                        tableCell
                      />
                    );
                  }
                }
              },
              {
                label: t('table.image'),
                name: 'image',
                options: {
                  ...defaultCellProps,
                  download: false,
                  print: false,
                  filter: false,
                  customBodyRenderLite: (dataIndex) => {
                    return (
                      <div className={classes.imageCell}>
                        <ImagePresenter
                          src={checklist.items[dataIndex].imageUrl || ''}
                          alt={checklist.items[dataIndex].name}
                        />
                      </div>
                    );
                  }
                }
              },
              {
                label: t('table.name'),
                name: 'name',
                options: defaultCellProps
              },
              {
                label: t('table.description'),
                name: 'description',
                options: {
                  setCellHeaderProps: () => {
                    return {
                      className: classes.headerCell
                    };
                  },
                  setCellProps: () => {
                    return {
                      className: classes.tableCellDescription
                    };
                  }
                }
              },
              {
                label: t('table.roomName'),
                name: 'roomName',
                options: defaultCellProps
              },
              {
                label: t('table.status'),
                name: 'status',
                options: {
                  ...defaultCellProps,
                  customBodyRender: (value) => {
                    const statusName = checklist.statuses.find(
                      (status) => status.id === value
                    )?.name;
                    return t(`checklist.${statusName?.toLowerCase()}`);
                  }
                }
              }
            ]}
            data={checklist.items}
            options={{
              responsive: 'vertical',
              sortOrder: {
                name: 'created',
                direction: 'desc'
              },
              print: false,
              rowsPerPage: checklist.items.length,
              selectableRows: 'none',
              elevation: 0
            }}
            components={{
              TableFooter: () => null,
              TableBody: (props) =>
                fetchingChecklistItems ? <Progress /> : <TableBody {...props} />
            }}
          />
        ) : fetchingChecklistItems ? (
          <Progress />
        ) : (
          <Typography>{t('checklist.noItemsFound')}</Typography>
        )}
      </Grid>
    </Grid>
  );
}
