import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    phaseContent: {
      minHeight: '350px'
    },
    progressContainer: {
      minHeight: '350px',
      display: 'flex',
      margin: 'auto',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
);
