import { DeadlineType } from './../components/Header/Deadlines/types';
import { ProjectsStateContext } from './../store/projectsState/ProjectsStateProvider';
import { getDeadlines } from 'axiosInstances';
import { useContext, useEffect, useState } from 'react';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { ApartmentsStateContext } from 'store/apartmentsState/ApartmentsStateProvider';

const useGetDeadlines = () => {
  const [deadlines, setDeadlines] = useState<DeadlineType[]>([]);

  const { globalState } = useContext(GlobalStateContext);
  const { projectsState } = useContext(ProjectsStateContext);
  const { apartmentsState } = useContext(ApartmentsStateContext);

  const { organisationId, tenantId } = globalState.startParameters;
  useEffect(() => {
    (async () => {
      // fetch deadlines
      if (
        apartmentsState.selectedApartment &&
        projectsState.selectedProject &&
        projectsState.selectedProject.id === apartmentsState.selectedApartment.projectId &&
        globalState.token &&
        tenantId &&
        organisationId
      ) {
        const deadlineData = await getDeadlines({
          organisationId,
          tenantId,
          gbToken: globalState.token,
          projectId: String(projectsState.selectedProject.id),
          apartmentId: String(apartmentsState.selectedApartment.id)
        });

        setDeadlines(deadlineData.calendarEvents);
      }
    })();
  }, [
    apartmentsState.selectedApartment,
    globalState.token,
    organisationId,
    projectsState.selectedProject,
    tenantId,
    setDeadlines
  ]);

  return deadlines;
};

export default useGetDeadlines;
