import { ChatStateType } from './types';
import { ChatStateAction, ChatStateActionType } from './actions';
import { ChatMessageType, ChatRoomThreadType } from 'components/Chat/types';

export type ChatStateReducerType = (
  state: ChatStateType,
  action: ChatStateActionType
) => ChatStateType;

const chatStateReducer = (chatState: ChatStateType, action: ChatStateActionType): ChatStateType => {
  switch (action.type) {
    case ChatStateAction.SET_THREADS:
      return {
        ...chatState,
        threads: action.data
      };
    case ChatStateAction.SET_CHAT_ROOM:
      return {
        ...chatState,
        thread: action.data
      };

    case ChatStateAction.SET_NEW_CHAT:
      return {
        ...chatState,
        newChat: action.data
      };
    case ChatStateAction.SET_IS_REFRESHING:
      return {
        ...chatState,
        isRefreshing: action.data
      };
    case ChatStateAction.SET_LAST_REFRESH_TIME:
      return {
        ...chatState,
        lastRefresh: new Date().getTime()
      };
    case ChatStateAction.SET_BULLETINS:
      return {
        ...chatState,
        bulletins: action.data
      };
    case ChatStateAction.SET_CHAT_VISIBILITY:
      return {
        ...chatState,
        chatVisibility: action.data
      };
    case ChatStateAction.SEND_MESSAGE_TO_THREAD:
      return {
        ...chatState,
        threads: chatState.threads.map((thread) => {
          if (thread.room.id === action.data.roomId) {
            const newMessage: ChatMessageType = {
              content: action.data.message,
              discussionEnd: 0,
              isFromUser: 1,
              id: new Date().getMilliseconds(),
              isInternal: 0,
              name: 'foo',
              userName: action.data.username,
              sendTime: new Date().getMilliseconds(),
              userID: action.data.userId
            };
            const newThread: ChatRoomThreadType = {
              room: thread.room,
              messages: [...thread.messages, newMessage]
            };
            return newThread;
          }

          return thread;
        }),
        thread:
          action.data.roomId === chatState.thread?.room.id
            ? {
                room: chatState.thread.room,
                messages: [
                  ...chatState.thread.messages,
                  {
                    content: action.data.message,
                    discussionEnd: 0,
                    isFromUser: 1,
                    id: new Date().getMilliseconds(),
                    isInternal: 0,
                    name: 'foo',
                    userName: action.data.username,
                    sendTime: new Date().valueOf() / 1000,
                    userID: action.data.userId
                  }
                ]
              }
            : chatState.thread
      };
    default:
      throw new Error('Invalid Chat Action');
  }
};

export default chatStateReducer;
