import { useContext, useEffect, useState } from 'react';
import { ApartmentsStateContext } from 'store/apartmentsState/ApartmentsStateProvider';
import { ProjectsStateContext } from 'store/projectsState/ProjectsStateProvider';

export default function useBasePath(): string {
  const { apartmentsState } = useContext(ApartmentsStateContext);
  const { projectsState } = useContext(ProjectsStateContext);
  const [path, setPath] = useState<string>('');

  useEffect(() => {
    if (!apartmentsState.selectedApartment || !projectsState.selectedProject) return;

    setPath(
      `/project/${projectsState.selectedProject?.id}/apartment/${apartmentsState.selectedApartment?.id}`
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apartmentsState.selectedApartment?.id, projectsState.selectedProject?.id]);

  return path;
}
