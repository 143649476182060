import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      paddingLeft: '0',
      paddingRight: '0',
      cursor: 'pointer',
      grow: 1
    },
    listItem: {
      paddingLeft: '0.45rem'
    }
  });
});
