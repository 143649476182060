import { AxiosResponse } from 'axios';
import { instance } from 'axiosInstances';
import { Contact } from 'hooks/useGetContacts';
import CheckListResponse from './CheckListResponse';
import { AttachmentsResponse } from './types';
import { createConfig, createPrivatePath } from './utils';

interface AttachmentParams {
  gbToken: string;
  organisationId: string;
  tenantId: string;
  projectId: string;
}

interface FetchAttachmentsParams extends AttachmentParams {
  apartmentId: string;
  sectionId?: string | null;
  limit?: Number;
}

interface UpdateAttachmentsParams extends AttachmentParams {
  attachmentId: string;
  xfdf: string;
}

export const fetchAttachments = async ({
  gbToken,
  organisationId,
  tenantId,
  projectId,
  apartmentId,
  sectionId,
  limit
}: FetchAttachmentsParams): Promise<AttachmentsResponse> => {
  let path =
    createPrivatePath({ organisationId, tenantId, projectId }) +
    `/attachments/apartment/${apartmentId}/sections/${sectionId}`;

  if (limit) path += `?limit=${limit}`;

  const { data } = await instance.get(path, createConfig({ token: gbToken }));

  if (data.error) throw new Error('Unable to fetch attachments!');

  return data;
};

export const signDocument = async ({
  gbToken,
  organisationId,
  tenantId,
  projectId,
  attachmentId,
  xfdf
}: UpdateAttachmentsParams): Promise<AttachmentsResponse> => {
  let path =
    createPrivatePath({ organisationId, tenantId, projectId }) +
    `/attachments/${attachmentId}/sign`;

  const { data } = await instance.put(path, { xfdf }, createConfig({ token: gbToken }));

  if (data.error) throw new Error('Unable to update signature!');

  return data;
};

interface FetchChecklistParams {
  apartmentId: string;
  gbToken: string;
}

export const fetchChecklist = async ({
  apartmentId,
  gbToken
}: FetchChecklistParams): Promise<AxiosResponse<CheckListResponse>> =>
  instance.get(
    `/v3/private/apartments/${apartmentId}/summary/checklist`,
    createConfig({ token: gbToken })
  );

interface FetchContactsParams {
  organisationId: string;
  tenantId: string;
  projectId: string;
  gbToken: string;
}

interface ContactSection {
  contacts?: Contact[];
  id: number;
}
interface ContactPhase {
  sections?: ContactSection[];
  id: number;
}

interface FetchContactsResponse {
  data: {
    phases: ContactPhase[];
  };
}

export const fetchContacts = ({
  organisationId,
  tenantId,
  projectId,
  gbToken
}: FetchContactsParams): Promise<AxiosResponse<FetchContactsResponse>> =>
  instance.get(
    `v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/cj/contacts`,
    createConfig({ token: gbToken })
  );
