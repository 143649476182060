import {
  AttachmentViewerState,
  AttachmentViewerStateAction
} from 'store/attachmentViewerState/interfaces';
import { AttachmentFileType, AttachmentType } from 'store/sectionsState/interfaces';

export const getAttachmentFileType = (attachment: AttachmentType): AttachmentFileType => {
  const { mimeType, signable, signed } = attachment;

  if (signable) return AttachmentFileType.SIGNABLE;
  else if (signed) return AttachmentFileType.SIGNED;

  if (!mimeType) return AttachmentFileType.OTHER;

  if (mimeType.includes('image')) return AttachmentFileType.IMAGE;
  else if (mimeType.includes('pdf')) return AttachmentFileType.PDF;
  else if (mimeType.includes('video')) return AttachmentFileType.VIDEO;

  return AttachmentFileType.OTHER;
};

export const createAttachmentViewerState = (
  attachment: AttachmentType,
  titleKey?: string
): AttachmentViewerState => {
  const type = getAttachmentFileType(attachment);
  const { name, url, xfdf, id, signable, signed } = attachment;

  return {
    document: {
      name,
      type,
      url,
      xfdf,
      id,
      signed
    },
    downloadLink: !signable || type === AttachmentFileType.IMAGE ? url : '',
    titleKey: titleKey || getDefaultTitleKey(attachment)
  };
};

export const openAttachmentModal = (attachment: AttachmentType, dispatch: any) => {
  const state = createAttachmentViewerState(attachment);
  const document = state.document;
  const type = document?.type;

  if (type === AttachmentFileType.OTHER) {
    window.open(attachment.url, '_blank');
    return;
  }

  dispatch({
    type: AttachmentViewerStateAction.OPEN,
    data: {
      ...state,
      showDownloadButton: type === AttachmentFileType.IMAGE
    }
  });
};

const getDefaultTitleKey = (attachment: AttachmentType): string => {
  const { signed, signable } = attachment;
  let key = 'attachments';

  if (signable) key += '.signDocument';
  else if (signed) key += '.signedDocument';
  else key += '.attachmentViewer';

  return key;
};
