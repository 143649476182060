export interface ApartmentProperty {
  useGb4dHd: boolean;
}

export enum ApartmentRoomType {
  DRY = 'DRY',
  WET = 'WET',
  HALL = 'HALL',
  OTHER1 = 'OTHER1',
  SAUNA = 'SAUNA',
  WC = 'WC'
}

export interface MessageCountType {
  totalCount: number;
  newCount: number;
}

export interface MessageSummary {
  massMessages: MessageCountType;
  newCount: number;
  roomMessages: MessageCountType;
  totalCount: number;
}

export interface ChatMessage {
  content: string;
  id: number;
  name: string;
  discussionEnd: number;
  isFromUser: number;
  isInternal: number;
  sendTime: number;
  userID: number;
  userName: string;
}

export interface Room {
  comment: string;
  messages: number;
  messagesContent?: ChatMessage[];
  roomType: ApartmentRoomType;
  type: ApartmentRoomType;
  id: number;
  name: string;
}

export interface Apartment {
  properties?: ApartmentProperty;
  rooms?: Room[];
  messageSummary?: MessageSummary;
  projectId?: number;
  id: number;
  name: string;
}

export interface ApartmentsState {
  apartments: Apartment[];
  fetchingApartments: boolean;
  selectedApartment: Apartment | null;
  selectingApartment: boolean;
  apartmentsFetched: boolean;
  apartmentInfoFetched: boolean;
}

export enum ApartmentsStateAction {
  SET_APARTMENTS = 'SET_APARTMENTS',
  SET_FETCHING_APARTMENTS = 'SET_FETCHING_APARTMENTS',
  SET_SELECTING_APARTMENT = 'SET_SELECTING_APARTMENT',
  SET_SELECTED_APARTMENT = 'SET_SELECTED_APARTMENT',
  SET_APARTMENTS_FETCHED = 'SET_APARTMENTS_FETCHED',
  SET_APARTMENT_INFO_FETCHED = 'SET_APARTMENT_INFO_FETCHED'
}

export interface SetApartmentsAction {
  type: ApartmentsStateAction.SET_APARTMENTS;
  data: Apartment[];
}

export interface SetFetchingApartmentsAction {
  type: ApartmentsStateAction.SET_FETCHING_APARTMENTS;
  data: boolean;
}

export interface SetSelectingApartmentAction {
  type: ApartmentsStateAction.SET_SELECTING_APARTMENT;
  data: boolean;
}

export interface SetSelectedApartmentAction {
  type: ApartmentsStateAction.SET_SELECTED_APARTMENT;
  data: Apartment | null;
}

export interface SetApartmentsFetchedAction {
  type: ApartmentsStateAction.SET_APARTMENTS_FETCHED;
  data: boolean;
}

export interface SetApartmentInfoFetchedAction {
  type: ApartmentsStateAction.SET_APARTMENT_INFO_FETCHED;
  data: boolean;
}

export type ApartmentsStateReducerType = (
  state: ApartmentsState,
  action: ApartmentsStateActionType
) => ApartmentsState;

export type ApartmentsStateActionType =
  | SetApartmentsAction
  | SetFetchingApartmentsAction
  | SetSelectedApartmentAction
  | SetSelectingApartmentAction
  | SetApartmentsFetchedAction
  | SetApartmentInfoFetchedAction;
