import { GlobalState } from './interfaces';

const initialGlobalState: GlobalState = {
  startParameters: {},
  tenantSettings: {},
  token: null,
  applicationError: null,
  fetchingTenantSettings: false,
  tenantSettingsFetched: false,
  userRights: null
};

export default initialGlobalState;
