import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { fetchBulletins, fetchRoomMessages } from 'axiosInstances';
import { useCallback, useContext, useEffect } from 'react';
import { ChatStateAction } from 'store/chatState/actions';
import { ChatStateContext } from 'store/chatState/ChatStateProvider';
import { ApartmentsStateContext } from 'store/apartmentsState/ApartmentsStateProvider';
import { Room } from 'store/apartmentsState/interfaces';

const useChatMessages = (disableRefresh = false) => {
  const { dispatch } = useContext(ChatStateContext);
  const { apartmentsState } = useContext(ApartmentsStateContext);
  const { globalState } = useContext(GlobalStateContext);

  const syncMessages = useCallback(async () => {
    if (apartmentsState.selectedApartment && globalState.token) {
      const rooms: Room[] = apartmentsState.selectedApartment?.rooms || [];

      if (!disableRefresh) dispatch({ type: ChatStateAction.SET_IS_REFRESHING, data: true });
      const threads = await Promise.all(
        rooms.map(async (room) => {
          const messagesResponse = await fetchRoomMessages({
            gbToken: String(globalState.token),
            roomId: '' + room.id
          });

          return { messages: messagesResponse.data.data, room };
        })
      );

      const bulletinsResponse = await fetchBulletins({
        gbToken: String(globalState.token),
        apartmentId: '' + apartmentsState.selectedApartment?.id
      });

      dispatch({ type: ChatStateAction.SET_THREADS, data: threads });
      dispatch({ type: ChatStateAction.SET_LAST_REFRESH_TIME });
      dispatch({
        type: ChatStateAction.SET_BULLETINS,
        data: bulletinsResponse.data.data
      });
      if (!disableRefresh) dispatch({ type: ChatStateAction.SET_IS_REFRESHING, data: false });
    }
  }, [disableRefresh, apartmentsState.selectedApartment, dispatch, globalState.token]);

  useEffect(() => {
    syncMessages();

    const interval = !disableRefresh ? setInterval(syncMessages, 30000) : null;
    return interval ? () => clearInterval(interval) : undefined;
  }, [syncMessages, disableRefresh]);

  return syncMessages;
};

export default useChatMessages;
