import { Apartment, ApartmentsStateAction } from './../store/apartmentsState/interfaces';
import { getApartments } from './../axiosInstances/instances/app';
import { useCallback, useContext, useEffect } from 'react';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { ApartmentsStateContext } from 'store/apartmentsState/ApartmentsStateProvider';
import { ProjectsStateContext } from 'store/projectsState/ProjectsStateProvider';

const useApartmentsFetcher = () => {
  const { globalState } = useContext(GlobalStateContext);
  const { apartmentsState, dispatch } = useContext(ApartmentsStateContext);
  const { organisationId, tenantId } = globalState.startParameters;
  const { projectsState } = useContext(ProjectsStateContext);

  const fetchApartments = useCallback(async () => {
    if (
      !apartmentsState.apartmentsFetched &&
      globalState.token &&
      organisationId &&
      tenantId &&
      projectsState.selectedProject?.id
    ) {
      dispatch({
        type: ApartmentsStateAction.SET_FETCHING_APARTMENTS,
        data: true
      });

      const { data } = await getApartments({
        gbToken: globalState.token,
        organisationId,
        tenantId,
        projectId: String(projectsState.selectedProject.id)
      });

      const apartmentsMapped: Apartment[] = data.data.map((ap) => ({
        id: ap.id,
        name: ap.name
      }));

      if (apartmentsMapped) {
        dispatch({
          type: ApartmentsStateAction.SET_APARTMENTS,
          data: apartmentsMapped
        });

        dispatch({
          type: ApartmentsStateAction.SET_SELECTED_APARTMENT,
          data:
            apartmentsMapped.find(
              (apartment: Apartment) =>
                String(apartment.id) === globalState.startParameters.apartmentId
            ) || apartmentsMapped[0]
        });

        dispatch({
          type: ApartmentsStateAction.SET_APARTMENTS_FETCHED,
          data: true
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apartmentsState.apartmentsFetched,
    dispatch,
    globalState.token,
    projectsState.selectedProject,
    organisationId,
    tenantId
  ]);

  useEffect(() => {
    fetchApartments();
  }, [fetchApartments]);
};

export default useApartmentsFetcher;
