import { Typography, Grid, Box } from '@material-ui/core';
import useBasePath from 'hooks/useBasePath';
import useT from 'hooks/useT';
import { Button, ButtonType } from '@groupbuilderoy/gb-components-library';
import { IconKeys } from '@groupbuilderoy/gb-components-library';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { PhasesStateContext } from 'store/phasesState/PhasesStateProvider';
import { Section, SectionsStateAction } from 'store/sectionsState/interfaces';
import { SectionsStateContext } from 'store/sectionsState/SectionsStateProvider';

import useStyles from './styles';

interface SectionCardProps {
  component: Section;
  buttonText?: string;
  action?: Function;
  children?: React.ReactNode;
  width?: number | string;
  to?: string;
}

export default function SectionCard({
  component,
  children,
  action,
  buttonText,
  to
}: SectionCardProps) {
  const t = useT();
  const { name, description, hideActionButton } = component;
  const { globalState } = useContext(GlobalStateContext);
  const { phasesState } = useContext(PhasesStateContext);
  const history = useHistory();
  const basePath = useBasePath();

  const { dispatch } = useContext(SectionsStateContext);
  const styleWidth = globalState.tenantSettings?.defaultColumnWidth;
  const classes = useStyles({ width: styleWidth });

  const sectionUrl = `${basePath}/phase/${phasesState.selectedPhase?.id}/section/${component.id}`;

  return (
    <Box className={classes.root}>
      <Grid
        container
        className={classes.gridContainer}
        direction="column"
        spacing={1}
        justifyContent="space-between"
      >
        <Grid item container>
          <Grid item container direction="column" className={classes.sectionHeader}>
            <Grid item>
              <Typography className={classes.title}>{name}</Typography>
            </Grid>
            {description && (
              <Grid item>
                <Typography className={classes.subheader}>{description}</Typography>
              </Grid>
            )}
          </Grid>
          <Grid item className={classes.content}>
            {children}
          </Grid>
        </Grid>

        <Grid item container direction="row" className={classes.actionArea}>
          {!hideActionButton && (
            <Button
              onClick={
                action
                  ? () => action()
                  : () => {
                      dispatch({
                        type: SectionsStateAction.SET_SELECTED_SECTION,
                        data: component
                      });
                      history.push(sectionUrl);
                    }
              }
              caption={buttonText || t('viewAll')}
              endIcon={IconKeys.forward}
              type={ButtonType.outlined}
              fitContainer={true}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
