import { fetchContacts } from 'axiosInstances';
import { useCallback, useContext, useEffect, useState } from 'react';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { PhasesStateContext } from 'store/phasesState/PhasesStateProvider';
import { ProjectsStateContext } from 'store/projectsState/ProjectsStateProvider';

export interface Contact {
  description: string | null;
  tenantId: string;
  partnerType: number;
  imageFileName: string | null;
  phoneNumber: string | null;
  email: string | null;
  streetAddress: string | null;
  city: string | null;
  postalCode: string | null;
  state: string | null;
  country: string | null;
  link: string | null;
  companyName: string | null;
  jobTitle: string | null;
  imageUrlSmall: string | null;
  imageUrlLarge: string | null;
  id: number | string;
  name: string | null;
}

const useGetContacts = (sectionId: number) => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const { globalState } = useContext(GlobalStateContext);
  const { projectsState } = useContext(ProjectsStateContext);
  const { phasesState } = useContext(PhasesStateContext);

  const { organisationId, tenantId } = globalState.startParameters;
  const projectId = projectsState.selectedProject?.id;
  const gbToken = globalState.token;
  const phaseId = phasesState.selectedPhase?.id;

  const doFetch = useCallback(async () => {
    if (organisationId && tenantId && projectId && gbToken) {
      const contactsResponse = await fetchContacts({
        organisationId,
        tenantId,
        projectId: String(projectId),
        gbToken
      });

      const contacts = contactsResponse.data.data.phases.reduce((contactsFromPhases, phase) => {
        if (phase.id === phaseId) {
          const contactsFromPhase =
            phase.sections?.reduce((contactsFromSections, section) => {
              if (section.id === sectionId) contactsFromSections.push(...(section.contacts || []));
              return contactsFromSections;
            }, [] as Contact[]) || [];

          contactsFromPhases.push(...contactsFromPhase);
        }

        return contactsFromPhases;
      }, [] as Contact[]);

      setContacts(contacts);
    }
  }, [organisationId, tenantId, projectId, gbToken, phaseId, sectionId]);

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  return contacts;
};

export default useGetContacts;
