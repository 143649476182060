import { CalendarSection } from 'store/sectionsState/interfaces';
import SectionCard from '../SectionCard/SectionCard';

import EventList from './variations/List/CalendarList';

export default function Calendar(props: CalendarSection) {
  return (
    <SectionCard component={{ ...props, hideActionButton: true }}>
      <EventList events={props.events} />
    </SectionCard>
  );
}
