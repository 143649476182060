import { PhasesStateAction, PhasesStateReducerType } from './interfaces';

const phasesStateReducer: PhasesStateReducerType = (phasesState, action) => {
  switch (action.type) {
    case PhasesStateAction.SET_PHASES:
      return {
        ...phasesState,
        fetchingPhases: false,
        fetchedPhases: true,
        phases: action.data
      };
    case PhasesStateAction.SET_FETCHING_PHASES:
      return {
        ...phasesState,
        fetchingPhases: action.data
      };
    case PhasesStateAction.SET_PHASES_FETCHED:
      return {
        ...phasesState,
        phasesFetched: action.data
      };
    case PhasesStateAction.SET_SELECTED_PHASE:
      return {
        ...phasesState,
        selectedPhase: action.data,
        selectingPhase: false
      };
    case PhasesStateAction.SET_SELECTING_PHASE:
      return {
        ...phasesState,
        selectingPhase: action.data
      };
    default:
      throw new Error('Invalid reducer action.');
  }
};

export default phasesStateReducer;
