import { ThemeOptions } from '@material-ui/core';
import { CustomThemeType, PaletteType } from './types';

export const defaultColors: PaletteType = {
  primary: '#B7835F',
  secondary: '#EFEEE4',
  border: 'rgba(0,0,0,.15)',
  accent: '#B7835F',
  buttonBorder: '#D0D5DD',
  buttonText: '#344054',
  buttonBGHover: '#B7835F',
  navBarPrimary: '#05061B',
  navBarSecondary: '#F2F4F7'
};

const defaultFont = ['Helvetica Neue', 'sans-serif'].join(',');

export const customBreakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
  mobileMenu: 1100,
  mobilePhasesBar: 768
};

const baseTheme = (themeOverrides: CustomThemeType = {}) => {
  const { palette, typography } = themeOverrides;
  const colors = {
    ...defaultColors,
    ...palette
  };

  return {
    palette: {
      primary: {
        main: colors.primary
      },
      secondary: {
        main: colors.secondary
      },
      button: {
        text: colors.buttonText,
        main: colors.buttonBorder
      },
      navBarPrimary: {
        main: colors.navBarPrimary,
        light: '',
        dark: '',
        contrastText: ''
      },
      navBarSecondary: {
        main: colors.navBarSecondary,
        light: '',
        dark: '',
        contrastText: ''
      }
    },
    props: {
      MuiButton: {
        variant: 'outlined'
      }
    },
    typography: {
      fontFamily: typography || defaultFont
    },
    breakpoints: {
      ...customBreakpoints
    },
    overrides: {
      MuiTabs: {
        indicator: {
          backgroundColor: colors.primary
        }
      },
      MuiIconButton: {
        colorPrimary: {
          color: colors.buttonText
        }
      },
      MuiButton: {
        root: {
          borderRadius: '8px',
          fontFamily: typography || defaultFont
        },
        outlined: {
          backgroundColor: 'white',
          color: colors.buttonText,
          border: `1px solid ${colors.buttonBorder} !important`,
          boxShadow: 'none',
          textTransform: 'uppercase',
          '&:hover': {
            backgroundColor: `${colors.secondary} !important`
          },
          transition: 'none'
        },
        outlinedPrimary: {
          color: colors.buttonText
        },
        contained: {
          backgroundColor: colors.primary,
          color: 'white',
          boxShadow: 'none',
          textTransform: 'uppercase'
        },
        text: {
          borderRadius: '0',
          backgroundColor: 'transparent',
          color: colors.primary,
          textTransform: 'uppercase',
          '&:hover': {
            color: 'white !important',
            backgroundColor: 'transparent'
          }
        }
      }
    },
    custom: {
      sectionCard: {
        header: {
          borderBottom: `0.15rem solid ${colors.primary}`
        }
      },
      sectionNavigation: {
        backgroundColor: 'white',
        borderBottom: `1px solid ${colors.border}`,

        '& span': {
          color: 'black'
        },
        '& img': {
          filter:
            'invert(0%) sepia(94%) saturate(25%) hue-rotate(70deg) brightness(104%) contrast(106%)'
        },

        '& a': {
          color: colors.link,
          backgroundColor: 'trasparent',
          borderBottom: `3px solid transparent`,

          '&.highlight, &:hover': {
            borderBottom: `3px solid ${colors.link}`
          }
        }
      }
    }
  } as ThemeOptions;
};

export default baseTheme;
