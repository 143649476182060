import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      cursor: 'pointer'
    },
    header: {
      color: theme.palette.primary.light,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightMedium
    } as CSSProperties,
    description: {
      color: theme.palette.common.black,
      fontSize: theme.typography.subtitle2.fontSize,
      lineHeight: '1.1'
    },
    date: {
      color: theme.palette.grey[500],
      fontSize: theme.typography.subtitle2.fontSize
    }
  });
});
