import { Room } from 'store/apartmentsState/interfaces';

export interface ChatMessageType {
  content: string;
  discussionEnd: number;
  id: number;
  isFromUser: number;
  isInternal: number;
  sendTime: number;
  userID: number;
  userName: string;
  name: string;
}

export enum ChatStatusType {
  OPEN = 0,
  CLOSED = 1
}

export interface ChatRoomThreadType {
  room: Room;
  messages: ChatMessageType[];
}

export enum BulletinReadStatusType {
  UNREAD = 0,
  READ = 1
}

export interface BulletinType {
  attachmentFileDisplayName: null | string;
  attachmentFilePath: null | string;
  attachmentFilename: null | string;
  attachmentId: null | string;
  content: string;
  received: BulletinReadStatusType;
  sendTime: number;
  userName: string;
  id: number;
  name: string;
}
