import { SectionsStateContext } from 'store/sectionsState/SectionsStateProvider';
import { getSectionsForPhase } from 'axiosInstances';
import { useCallback, useContext, useEffect } from 'react';
import { GlobalStateContext } from 'store/globalState/GlobalStateProvider';
import { PhasesStateContext } from 'store/phasesState/PhasesStateProvider';
import { SectionsStateAction } from 'store/sectionsState/interfaces';

const useSectionsFetcher = () => {
  const { phasesState } = useContext(PhasesStateContext);
  const { globalState } = useContext(GlobalStateContext);
  const { dispatch } = useContext(SectionsStateContext);
  const { organisationId, tenantId } = globalState.startParameters;
  const gbToken = globalState.token;

  const fetchSections = useCallback(async () => {
    if (phasesState.selectedPhase && organisationId && tenantId && gbToken) {
      dispatch({ type: SectionsStateAction.SET_FETCHING_SECTIONS, data: true });
      const sectionsResponse = await getSectionsForPhase({
        phaseId: String(phasesState.selectedPhase.id),
        organisationId,
        tenantId,
        gbToken
      });

      dispatch({
        type: SectionsStateAction.SET_SECTIONS,
        data: sectionsResponse.data.data
      });
    }
  }, [phasesState.selectedPhase, organisationId, tenantId, gbToken, dispatch]);

  useEffect(() => {
    fetchSections();
  }, [fetchSections]);
};

export default useSectionsFetcher;
