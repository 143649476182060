import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const progressSize: number = 25;

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: '10px 12px',
      height: '42px',
      minWidth: '80px',
      position: 'relative',
      fontSize: theme.typography.subtitle1.fontSize,

      '&.loading': {
        pointerEvents: 'none',
        paddingTop: '8px',

        '& .children': {
          visibility: 'hidden',
          margin: 'auto'
        }
      },

      '& .progress': {
        width: `${progressSize}px !important`,
        height: `${progressSize}px !important`,
        position: 'absolute',
        left: `calc(50% - ${progressSize / 2}px)`,
        top: '6px'
      }
    }
  })
);
