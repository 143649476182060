import { ApartmentsState } from './interfaces';

const initialApartmentsState: ApartmentsState = {
  apartments: [],
  fetchingApartments: false,
  selectedApartment: null,
  selectingApartment: false,
  apartmentsFetched: false,
  apartmentInfoFetched: false
};

export default initialApartmentsState;
