import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => {
  return createStyles({
    icon: {
      marginRight: '1rem',
      color: theme.palette.grey[700]
    },
    iconActionNeeded: {
      color: theme.palette.error.main
    }
  });
});
