import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    pageHeader: {
      fontSize: theme.typography.h5.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      margin: '1rem 0 1.5rem'
    } as CSSProperties,
    listContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '1rem',
      '&&>li': {
        '&:not(:last-child)': {
          borderRight: '1px solid lightgray'
        },
        '&:nth-child(3)': {
          borderRight: 'none !important'
        },
        display: 'block',
        maxWidth: 'calc(100%/3 - 1rem)',
        borderBottom: 'none',
        padding: '8px 1rem',
        margin: '2rem 0',
        [theme.breakpoints.down('md')]: {
          maxWidth: '100%',
          margin: '1rem 0',
          borderRight: 'none !important',
          '&:not(:last-child)': {
            borderBottom: '1px solid lightgray'
          }
        }
      }
    }
  })
);
