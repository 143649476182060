import { AttachmentViewerState } from './interfaces';

const initialAttachmentViewerState: AttachmentViewerState = {
  open: false,
  attachment: null,
  document: null,
  downloadLink: undefined,
  documentUpdated: false
};

export default initialAttachmentViewerState;
