//components
import Header from './components/Header/Header';
import Chat from 'components/Chat/Chat';
import { Container, ThemeProvider } from '@material-ui/core';
import Messaging from 'components/Messaging/Messaging';

// hooks
import React, { useContext } from 'react';

// contexts
import Footer from 'components/Footer/Footer';
import useSetupApp from 'hooks/useSetupApp';
import useLogin from 'hooks/useLogin';
import useTenantSettingsFetcher from 'hooks/useTenantSettingsFetcher';
import { ProjectsStateContext } from 'store/projectsState/ProjectsStateProvider';
import useProjectsFetcher from 'hooks/useProjectsFetcher';
import useApartmentsFetcher from 'hooks/useApartmentsFetcher';
import usePhasesFetcher from 'hooks/usePhasesFetcher';
import useSectionsFetcher from 'hooks/useSectionsFetcher';
import useApartmentInfoFetcher from 'hooks/useApartmentInfoFetcher';
import { ChatStateContext } from 'store/chatState/ChatStateProvider';
import AttachmentViewer from 'components/Attachments/AttachmentViewer/AttachmentViewer';
import { useGetUserRights } from 'hooks/useGetUserRights';
import { Route } from 'react-router-dom';
import { MessagingPaths } from '@groupbuilderoy/messaging-client';
import Home from 'components/Home/Home';
import useGetCustomizationSettings from 'hooks/useGetCustomizationSettings';
import defaultTheme from 'store/themes/default';

function App() {
  useSetupApp();
  useLogin();
  useTenantSettingsFetcher();
  useProjectsFetcher();
  useApartmentsFetcher();
  usePhasesFetcher();
  useSectionsFetcher();
  useApartmentInfoFetcher();
  useGetUserRights();

  const { projectsState } = useContext(ProjectsStateContext);
  const { chatState } = useContext(ChatStateContext);
  const customizationSettings = useGetCustomizationSettings();
  const theme = defaultTheme(customizationSettings.parsedSettings);

  return React.useMemo(
    () => (
      <ThemeProvider theme={theme}>
        <AttachmentViewer />
        <Header />
        <Container role="main" style={{ margin: '2rem auto' }}>
          <Route
            exact
            path={[MessagingPaths.ApartmentMessages, MessagingPaths.Bulletins]}
            component={Messaging}
          />
          <Route
            path={[
              '/',
              '/project/:projectId/apartment/:apartmentId/phase/:phaseId/section/:sectionId',
              '/project/:projectId/apartment/:apartmentId/phase/:phaseId',
              '/project/:projectId/apartment/:apartmentId',
              '/project/:projectId'
            ]}
            exact
            component={Home}
          />
          {chatState.chatVisibility && <Chat />}
          <Footer />
        </Container>
        <Footer />
      </ThemeProvider>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      chatState.chatVisibility,
      projectsState.selectedProject?.canUseMessaging,
      customizationSettings
    ]
  );
}

export default App;
