import React, { Dispatch, useMemo, useReducer } from 'react';
import attachmentViewerStateReducer from './attachmentViewerStateReducer';
import initialAttachmentViewerState from './initialAttachmentViewerState';
import {
  AttachmentViewerState,
  AttachmentViewerStateActionType,
  AttachmentViewerStateReducerType
} from './interfaces';

export const AttachmentViewerStateContext = React.createContext<{
  attachmentViewerState: AttachmentViewerState;
  dispatch: Dispatch<AttachmentViewerStateActionType>;
}>({
  attachmentViewerState: initialAttachmentViewerState,
  dispatch: () => initialAttachmentViewerState
});

interface AttachmentViewerStateProviderProps {
  children: React.ReactNode;
}

const AttachmentViewerStateProvider: React.FC<AttachmentViewerStateProviderProps> = ({
  children
}) => {
  const { Provider } = AttachmentViewerStateContext;
  const [attachmentViewerState, dispatch] = useReducer<AttachmentViewerStateReducerType>(
    attachmentViewerStateReducer,
    initialAttachmentViewerState
  );
  const contextValue = useMemo(
    () => ({ attachmentViewerState, dispatch }),
    [attachmentViewerState, dispatch]
  );

  return <Provider value={contextValue}>{children}</Provider>;
};

export default AttachmentViewerStateProvider;
